// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { useClusterInputsDependencies } from '@components/cluster/cluster-details-inputs/utils';
import { CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG_MAP } from '@streaming-projects/resource-definitions/cluster-resource-definitions/cluster-resource-inputs-config';
import {
  BASIC_CLUSTER_TYPE,
  BASIC_V2_CLUSTER_TYPE,
  CLUSTER_TYPE_BACKEND_NAME,
  DEDICATED_CLUSTER_TYPE,
  ENTERPRISE_CLUSTER_TYPE,
  STANDARD_CLUSTER_TYPE,
  STANDARD_V2_CLUSTER_TYPE,
} from '@src/constants';
import { getDropdownOptions } from '@components/stream-governance/utils';
import { SelectField } from '@src/formik-utils/FormFields';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';

export const SPClusterTypeContainer = ({ disabled, fluid = null, disableOnFormErrors = null }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const dataUniverse =
    useContext(SPMetaDataContext).metaData.clusterResourceInputConfigs.cluster_params;
  const clusterTypeConfig =
    CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG_MAP.get(CLUSTER_TYPE_BACKEND_NAME);

  useClusterInputsDependencies(
    clusterTypeConfig,
    dataUniverse,
    values,
    setFieldValue,
    setFieldTouched
  );

  const clusterTypeOptions = getDropdownOptions(
    dataUniverse,
    values,
    clusterTypeConfig,
    false,
    true,
    false,
    false,
    [
      BASIC_CLUSTER_TYPE,
      BASIC_V2_CLUSTER_TYPE,
      STANDARD_CLUSTER_TYPE,
      STANDARD_V2_CLUSTER_TYPE,
      ENTERPRISE_CLUSTER_TYPE,
      DEDICATED_CLUSTER_TYPE,
    ]
  );

  return (
    <SelectField
      disableOnFormErrors={disableOnFormErrors}
      disabled={disabled}
      fieldDisplayName={clusterTypeConfig.displayName}
      fieldName={clusterTypeConfig.backendFieldName}
      fluid={fluid}
      options={clusterTypeOptions}
      placeholder={null}
    />
  );
};
