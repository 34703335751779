// @flow

import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG_MAP } from '@streaming-projects/resource-definitions/cluster-resource-definitions/cluster-resource-inputs-config';
import { CLUSTER_REGION_BACKEND_NAME } from '@src/constants';
import { getFormattedRegionsUniverse } from '@src/common-utils/utils';
import { getDropdownOptions } from '@components/stream-governance/utils';
import { SelectField } from '@src/formik-utils/FormFields';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';
import { CLUSTER_RESOURCES_GRID_FIELDNAMES } from '@streaming-projects/orgs/enums';

export const SPClusterRegionsContainer = ({
  disabled,
  fluid = null,
  disableOnFormErrors = null,
}) => {
  const { values } = useFormikContext();
  const clusterRegionsConfig = CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG_MAP.get(
    CLUSTER_REGION_BACKEND_NAME
  );

  const dataUniverse =
    useContext(SPMetaDataContext).metaData.resource_region_details.region_details;

  const dataUniverseFormatted = getFormattedRegionsUniverse(
    dataUniverse,
    CLUSTER_RESOURCES_GRID_FIELDNAMES.PROVIDER_LABEL
  );

  const clusterRegionsOptions = getDropdownOptions(
    dataUniverseFormatted,
    values,
    clusterRegionsConfig,
    false,
    true,
    false,
    false,
    [],
    true
  );

  return (
    <SelectField
      disableOnFormErrors={disableOnFormErrors}
      disabled={disabled}
      fieldDisplayName={clusterRegionsConfig.displayName}
      fieldName={clusterRegionsConfig.backendFieldName}
      fluid={fluid}
      options={clusterRegionsOptions}
      placeholder={null}
    />
  );
};
