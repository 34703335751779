// @flow
import React, { useCallback, useMemo } from 'react';
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import { LIMIT_INDICATOR_FOR_FETCH_ALL } from '@streaming-projects/constants';
import { useGetStreamingProjectsHierarchyQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';

import { SPHierarchy } from './SPHierarchy';

export const SPHierarchyContainer = ({ limit, onLimitChange, searchTerm }) => (
  <DataFetcherContainer
    dataFetchingArgs={useMemo(
      () => [
        {
          query: searchTerm,
          limit: searchTerm !== '' ? LIMIT_INDICATOR_FOR_FETCH_ALL : limit,
        },
        {
          refetchOnMountOrArgChange: true,
        },
      ],
      [searchTerm, limit]
    )}
    dataFetchingFunction={useCallback(useGetStreamingProjectsHierarchyQuery, [])}
  >
    {({ accounts }) => {
      return (
        <SPHierarchy
          accounts={accounts}
          limit={limit}
          onLimitChange={onLimitChange}
          searchTerm={searchTerm}
        />
      );
    }}
  </DataFetcherContainer>
);
