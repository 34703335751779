// @flow
import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Spacer } from '@presentational/spacing/Spacer';

import { PrimaryButton } from './PrimaryButton';

export const AddNewButton = ({ onClick, text }) => (
  <PrimaryButton
    circular={true}
    compact={true}
    onClick={onClick}
    size="medium"
    style={{
      marginTop: '1rem',
    }}
    text={
      <>
        <Icon fitted={true} name="add circle" />
        <Spacer x={5} />
        {text}
      </>
    }
  />
);
