// @flow
import { Icon } from 'semantic-ui-react';
import React from 'react';

export const EditIcon = ({ onClick, disabled }) => (
  <Icon
    color="green"
    data-testid="delete-icon"
    disabled={disabled}
    name="edit"
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  />
);
