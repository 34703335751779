// @flow

import React, { useState } from 'react';

import { StyledAccordion } from '../../../common-utils/styledComponents';

export const handleAccordionTitleClick = (
  e,
  data,
  openAccordionIndexesArray,
  setOpenAccordionIndexesArray
) => {
  const { index } = data;
  let newState;
  if (openAccordionIndexesArray.includes(index)) {
    newState = openAccordionIndexesArray.filter((i) => i !== index);
  } else {
    newState = [...openAccordionIndexesArray, index];
  }
  setOpenAccordionIndexesArray(newState);
};

export const AccordionsList = ({
  panels,
  initiallyAllCollapsed = false,
  showOnlyTitleInCollapsedState = null,
  initialIndexes = initiallyAllCollapsed ? [] : [...Array(panels.length).keys()],
}) => {
  const [openAccordionIndexesArray, setOpenAccordionIndexesArray] = useState(initialIndexes);

  let panelsToUse = [...panels];

  if (showOnlyTitleInCollapsedState) {
    // If we should only show Title in collapsed state, then start from scratch, iterate over all panels and make content = null if the corresponding index is not expanded
    panelsToUse = [];
    for (let i = 0; i <= panels.length - 1; i++) {
      const currPanel = panels[i];
      if (!openAccordionIndexesArray.includes(i)) {
        panelsToUse.push({
          ...currPanel,
          content: null,
        });
      } else {
        panelsToUse.push(currPanel);
      }
    }
  }

  return (
    <StyledAccordion
      activeIndex={openAccordionIndexesArray}
      exclusive={false}
      onTitleClick={(e, data) =>
        handleAccordionTitleClick(e, data, openAccordionIndexesArray, setOpenAccordionIndexesArray)
      }
      panels={panelsToUse}
    />
  );
};
