// @flow
import React, { useContext } from 'react';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { SecondaryTopBar } from '@components/breadcrumbs/SecondaryTopBar';
import {
  getLinkForOrganizationsPage,
  getLinkForStreamingProjectsHomePage,
} from '@streaming-projects/links';
import { StyledSpan } from '@src/common-utils/styledComponents';
import { Link } from 'react-router-dom';

const getSPOrgSections = (spOrgData) => {
  const orgId = spOrgData?.org?.id;
  const orgName = spOrgData?.org?.sfdc_org_name;
  return [
    <a href={getLinkForStreamingProjectsHomePage()} key="home">
      Home
    </a>,
    <StyledSpan key="sp-org"> Organization </StyledSpan>,
    <Link key="sp-org-name" to={getLinkForOrganizationsPage(orgId)}>
      {orgName}
    </Link>,
  ];
};

export const SPOrgBreadcrumbs = () => {
  const spOrgData = useContext(SPOrgContext);
  return (
    <SecondaryTopBar sectionsToShow={getSPOrgSections(spOrgData)} showLastUpdatedMessage={false} />
  );
};
