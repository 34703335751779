// @flow
import React from 'react';

import { EditRenderer } from '../../../views/grid-utils/renderers';

import { SPFlinkConfigurationAddEditModal } from './SPFlinkConfigurationAddEditModal';

export const FlinkPoolEditRenderer = ({ data }) => {
  return (
    <EditRenderer>
      {(isEditModalOpen, setEditModalOpen) => {
        return (
          <SPFlinkConfigurationAddEditModal
            initialValues={data}
            isEditingMode={true}
            isOpen={isEditModalOpen}
            setOpen={setEditModalOpen}
          />
        );
      }}
    </EditRenderer>
  );
};
