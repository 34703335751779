// @flow
import { string } from 'yup';
import {
  RATE_CARD_DISPLAY_NAME,
  SFDC_ACCOUNT_ID_DISPLAY_NAME,
  SFDC_ACCOUNT_NAME_DISPLAY_NAME,
  SFDC_ORGANISATION_NAME_DISPLAY_NAME,
  SFDC_ORGANIZATION_ID_DISPLAY_NAME,
} from '@src/constants';
import { SELECT_INPUT_TYPE, TEXT_INPUT_TYPE } from '@src/formik-utils/consts';
import { convertConfigArrayToMap } from '@src/configuration/utils';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';

export const STREAMING_PROJECTS_ORG_DETAILS_CONFIG = [
  {
    displayName: SFDC_ACCOUNT_ID_DISPLAY_NAME,
    backendFieldName: `${BACKEND_FIELDNAMES.ACCOUNT_ID}`,
    validation: string().label(SFDC_ACCOUNT_ID_DISPLAY_NAME).required(),
    icon: { name: 'folder', color: 'blue' },
    inputType: TEXT_INPUT_TYPE,
  },
  {
    displayName: SFDC_ACCOUNT_NAME_DISPLAY_NAME,
    backendFieldName: `${BACKEND_FIELDNAMES.ACCOUNT_NAME}`,
    validation: string().label(SFDC_ACCOUNT_NAME_DISPLAY_NAME).required(),
    icon: { name: 'folder', color: 'blue' },
    inputType: TEXT_INPUT_TYPE,
  },
  {
    displayName: SFDC_ORGANIZATION_ID_DISPLAY_NAME,
    backendFieldName: `${BACKEND_FIELDNAMES.SFDC_ORG_ID}`,
    validation: string().label(SFDC_ORGANIZATION_ID_DISPLAY_NAME).required(),
    icon: { name: 'folder', color: 'teal' },
    inputType: TEXT_INPUT_TYPE,
  },
  {
    displayName: SFDC_ORGANISATION_NAME_DISPLAY_NAME,
    backendFieldName: `${BACKEND_FIELDNAMES.SFDC_ORG_NAME}`,
    validation: string().label(SFDC_ORGANISATION_NAME_DISPLAY_NAME).required(),
    icon: { name: 'folder', color: 'teal' },
    inputType: TEXT_INPUT_TYPE,
  },
  {
    displayName: RATE_CARD_DISPLAY_NAME,
    backendFieldName: `${BACKEND_FIELDNAMES.RATE_CARD}`,
    validation: string().label(RATE_CARD_DISPLAY_NAME).required(),
    inputType: SELECT_INPUT_TYPE,
  },
];

export const STREAMING_PROJECTS_ORG_DETAILS_CONFIG_MAP = convertConfigArrayToMap(
  STREAMING_PROJECTS_ORG_DETAILS_CONFIG
);

export const SP_ORG_TOP_LEVEL_CONFIG = [...STREAMING_PROJECTS_ORG_DETAILS_CONFIG];

// todo::SP Get this from where? Probably a separate call? Discuss with BE
export const SP_COMMIT_CONFIGS = {
  deal_types: ['New Commit', 'Renewal'],
  marketplaces: ['None', 'AWS', 'GCP', 'Azure'],
  payment_schedules: ['Prepaid', 'Arrears'],
  support_tiers: ['Free', 'Developer', 'Business', 'Premier'],
  rate_cards: [
    {
      id: 1,
      name: '8/1',
      label: 'Aug 1, 2020 (8/1) (Admins Only)',
      actionable: true,
    },
    {
      id: 2,
      name: '10/1',
      label: 'Oct 1, 2020 (10/1)',
      actionable: true,
    },
    {
      id: 3,
      name: '3/5/2024',
      label: 'Mar 5, 2024 (3/5/2024)',
      actionable: true,
    },
    {
      id: 4,
      name: '4/11/2024',
      label: 'Apr 11, 2024 (4/11/2024)',
      actionable: true,
    },
  ],
};
