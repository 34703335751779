// @flow
import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Spacer } from './spacing/Spacer';

const DangerTextWrapper = styled.span`
  color: red;
  font-size: ${(props) => props.fontSize || '1rem'}; // Default font size is 1rem
  line-height: 2;
`;

export const DangerText = ({ text, showIcon = true, fontSize = null }) => {
  return (
    <DangerTextWrapper fontSize={fontSize}>
      {showIcon && <Icon name="warning sign" />}
      <Spacer x={20} />
      <span>{text}</span>
    </DangerTextWrapper>
  );
};
