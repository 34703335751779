// @flow
import {
  FLINK_POOL_RESOURCES_GRID_FIELDNAMES,
  FLINK_POOL_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { string } from 'yup';
import { convertConfigArrayToMap } from '@src/configuration/utils';

export const FLINK_POOL_RESOURCE_INPUTS_DETAILS_CONFIG = [
  {
    displayName: FLINK_POOL_RESOURCES_GRID_HEADERS.NAME,
    backendFieldName: FLINK_POOL_RESOURCES_GRID_FIELDNAMES.NAME,
    icon: null,
    validation: string().label(FLINK_POOL_RESOURCES_GRID_HEADERS.NAME).required(),
  },
  {
    displayName: FLINK_POOL_RESOURCES_GRID_HEADERS.PROVIDER,
    backendFieldName: FLINK_POOL_RESOURCES_GRID_FIELDNAMES.PROVIDER,
    displayNameCol: FLINK_POOL_RESOURCES_GRID_FIELDNAMES.PROVIDER_LABEL,
    validation: string().required().label(FLINK_POOL_RESOURCES_GRID_HEADERS.PROVIDER).nullable(),
  },
  {
    displayName: FLINK_POOL_RESOURCES_GRID_HEADERS.REGION,
    backendFieldName: FLINK_POOL_RESOURCES_GRID_FIELDNAMES.REGION,
    validation: string().required().label(FLINK_POOL_RESOURCES_GRID_HEADERS.REGION).nullable(),
    parentFieldsInGroup: [FLINK_POOL_RESOURCES_GRID_FIELDNAMES.PROVIDER],
    displayNameCol: FLINK_POOL_RESOURCES_GRID_FIELDNAMES.REGION_LABEL,
  },
];

export const FLINK_POOL_RESOURCE_INPUTS_DETAILS_CONFIG_MAP = convertConfigArrayToMap(
  FLINK_POOL_RESOURCE_INPUTS_DETAILS_CONFIG
);

// todo::SP Change these constants to use new SP ones
