// @flow
import { configureStore } from '@reduxjs/toolkit';
import { streamingProjectsApi } from '@streaming-projects/service-definitions/streamingProjectsApi';

import { estimatesApi } from './service-definitions/estimates';

export const store = configureStore({
  reducer: {
    [estimatesApi.reducerPath]: estimatesApi.reducer,
    [streamingProjectsApi.reducerPath]: streamingProjectsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(estimatesApi.middleware, streamingProjectsApi.middleware),
});
