// @flow
import React, { useContext, useState } from 'react';
import {
  SP_SUMMARY_GRID_FIELDNAMES,
  SP_SUMMARY_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { DataGrid } from '@components/views/grid-utils/DataGrid';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { BASELINE_SP_IDENTIFIER_NAME } from '@streaming-projects/enums';
import { SPNameRenderer } from '@streaming-projects/orgs/org-top-level/sp-summary/SPNameRenderer';
import { SPDeleteRenderer } from '@streaming-projects/orgs/org-top-level/sp-summary/SPDeleteRenderer';
import { PrimaryButton } from '@presentational/buttons/PrimaryButton';
import { SPSPAddModal } from '@streaming-projects/sp-page/SPSPAddModal';

export const StreamingProjectsSummaryContainer = () => {
  const [isSPAddModalOpen, setSPAddModalOpen] = useState(false);
  const columnDefs = [
    {
      field: SP_SUMMARY_GRID_FIELDNAMES.NAME,
      headerName: SP_SUMMARY_GRID_HEADERS.NAME,
      cellRenderer: SPNameRenderer,
    },
    {
      field: SP_SUMMARY_GRID_FIELDNAMES.ID,
      headerName: SP_SUMMARY_GRID_HEADERS.ID,
      hide: true,
    },
    {
      field: SP_SUMMARY_GRID_FIELDNAMES.SFDC_ID,
      headerName: SP_SUMMARY_GRID_HEADERS.SFDC_ID,
    },
    {
      field: SP_SUMMARY_GRID_FIELDNAMES.START_DATE,
      headerName: SP_SUMMARY_GRID_HEADERS.START_DATE,
    },
    {
      field: SP_SUMMARY_GRID_FIELDNAMES.END_DATE,
      headerName: SP_SUMMARY_GRID_HEADERS.END_DATE,
    },
    {
      field: SP_SUMMARY_GRID_FIELDNAMES.VERSION,
      headerName: SP_SUMMARY_GRID_HEADERS.VERSION,
      hide: true,
    },
    {
      field: '',
      suppressSizeToFit: true,
      maxWidth: 50,
      cellRenderer: SPDeleteRenderer,
      filter: false,
      sortable: false,
    },
  ];
  const spORGData = useContext(SPOrgContext);

  let inputDataSource = spORGData?.sp_details ?? [];
  inputDataSource = inputDataSource.filter((x) => x.name !== BASELINE_SP_IDENTIFIER_NAME);

  const areAnySPsConfigured = inputDataSource.length > 0;

  return (
    <PaddedAndRaisedSegment>
      <PrimaryButton
        onClick={() => {
          setSPAddModalOpen(true);
        }}
        size="mini"
        text="Add New Streaming Project"
      />

      <SPSPAddModal isOpen={isSPAddModalOpen} setOpen={setSPAddModalOpen} />

      {areAnySPsConfigured && (
        <DataGrid
          columnDefs={columnDefs}
          inputDataSource={inputDataSource}
          label="Streaming Projects Summary"
          sizeColumnsToFitInitially={true}
        />
      )}
    </PaddedAndRaisedSegment>
  );
};
