// @flow

import { Formik } from 'formik';
import React from 'react';
import { object } from 'yup';
import { Grid } from 'semantic-ui-react';

import { Form } from '../../formik-utils/formikSUIWrapper';
import { TEXT_INPUT_TYPE } from '../../formik-utils/consts';
import { TextField } from '../../formik-utils/FormFields';

import { GoButton } from './buttons/GoButton';

export const SearchForm = ({ searchTerm, onClickHandler }) => {
  const columnConfig = {
    displayName: 'Search',
    backendFieldName: 'searchTerm',
    icon: 'search',
    inputType: TEXT_INPUT_TYPE,
  };

  const validationSchema = object({});

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ searchTerm }}
      onSubmit={(values) => onClickHandler(values.searchTerm)}
      validationSchema={validationSchema}
    >
      {({ values }) => {
        return (
          <Form autoComplete="off">
            <Grid>
              <Grid.Column textAlign="left" width={5}>
                <TextField
                  addLabel={false}
                  autoFocus={true}
                  fieldDisplayName={columnConfig.displayName}
                  fieldName={columnConfig.backendFieldName}
                  icon={columnConfig.icon}
                />
              </Grid.Column>
              <Grid.Column textAlign="left" verticalAlign="middle">
                <GoButton disabled={values.searchTerm == null || values.searchTerm.length === 0} />
              </Grid.Column>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
