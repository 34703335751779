// @flow
import React from 'react';
import { CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG_MAP } from '@streaming-projects/resource-definitions/cluster-resource-definitions/cluster-resource-inputs-config';
import { CLUSTER_NAME_BACKEND_NAME } from '@src/constants';
import { TextField } from '@src/formik-utils/FormFields';

export const SPClusterNameContainer = ({ disabled, fluid = null, disableOnFormErrors = null }) => {
  const clusterNameConfig =
    CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG_MAP.get(CLUSTER_NAME_BACKEND_NAME);
  return (
    <TextField
      disableOnFormErrors={disableOnFormErrors}
      disabled={disabled}
      fieldDisplayName={clusterNameConfig.displayName}
      fieldName={clusterNameConfig.backendFieldName}
      fluid={fluid}
      icon={clusterNameConfig.icon}
    />
  );
};
