// @flow
import React, { useContext, useState } from 'react';
import {
  CLUSTER_RESOURCES_GRID_FIELDNAMES,
  CLUSTER_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { PercentageRenderer } from '@components/views/grid-utils/renderers';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { SPClusterResourceConfigurationAddEditModal } from '@streaming-projects/resource-definitions/cluster-resource-definitions/SPClusterResourceConfigurationAddEditModal';
import { PrimaryButton } from '@presentational/buttons/PrimaryButton';
import { SPClusterDeleteRenderer } from '@src/streaming-projects/sp-top-level/SPClusterDeleteRenderer';
import { ClusterResourceEditRenderer } from '@streaming-projects/resource-definitions/cluster-resource-definitions/ClusterResourceEditRenderer';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';

import { DataGrid } from '../../../views/grid-utils/DataGrid';
import MappedDataRenderer from '../auxilary-components/MappedDataRenderer';

export const SPClusterResourceConfigurationsSummaryContainer = () => {
  const [isSPClusterConfigAddEditModalOpen, setSPClusterConfigAddEditModalOpen] = useState(false);
  const { providerRegionsMap, resourceConfigurationsNameLabelsMap, providerNameLabelMap } =
    useContext(SPMetaDataContext).metaData;

  const columnDefs = [
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.ID,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.ID,
      hide: true,
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.NAME,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.NAME,
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.TYPE,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.CLUSTER_TYPE,
      cellRenderer: ({ value }) => (
        <MappedDataRenderer
          mapping={
            resourceConfigurationsNameLabelsMap[CLUSTER_RESOURCES_GRID_FIELDNAMES.CLUSTER_TYPE]
          }
          value={value}
        />
      ),
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.NETWORKING_TYPE,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.NETWORKING_TYPE,
      cellRenderer: ({ value }) => (
        <MappedDataRenderer
          mapping={
            resourceConfigurationsNameLabelsMap[CLUSTER_RESOURCES_GRID_FIELDNAMES.NETWORKING_TYPE]
          }
          value={value}
        />
      ),
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.PROVIDER,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.PROVIDER,
      cellRenderer: ({ value }) => (
        <MappedDataRenderer mapping={providerNameLabelMap} value={value} />
      ),
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.REGION,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.REGION,
      cellRenderer: ({ data, value }) => (
        <MappedDataRenderer mapping={providerRegionsMap[data.Provider].regions} value={value} />
      ),
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.AVAILABILITY,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.AVAILABILITY,
      cellRenderer: ({ value }) => (
        <MappedDataRenderer
          mapping={
            resourceConfigurationsNameLabelsMap[CLUSTER_RESOURCES_GRID_FIELDNAMES.AVAILABILITY]
          }
          value={value}
        />
      ),
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.SLA,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.SLA,
      cellRenderer: PercentageRenderer,
    },
    {
      field: '',
      filter: false,
      cellRenderer: ClusterResourceEditRenderer,
      suppressSizeToFit: true,
      maxWidth: 60,
      sortable: false,
    },
    {
      field: '',
      filter: false,
      cellRenderer: SPClusterDeleteRenderer,
      suppressSizeToFit: true,
      maxWidth: 60,
      sortable: false,
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.VERSION,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.VERSION,
      hide: true,
    },
  ];

  const inputDataSource = useContext(SPOrgContext)?.resources?.cluster_resources ?? [];
  const areAnyClustersConfigured = inputDataSource.length > 0;

  return (
    <PaddedAndRaisedSegment>
      <PrimaryButton
        onClick={() => {
          setSPClusterConfigAddEditModalOpen(true);
        }}
        size="mini"
        text="Add Cluster Shared Resource Configuration"
      />

      <SPClusterResourceConfigurationAddEditModal
        isOpen={isSPClusterConfigAddEditModalOpen}
        setOpen={setSPClusterConfigAddEditModalOpen}
      />

      {areAnyClustersConfigured && (
        <DataGrid
          columnDefs={columnDefs}
          displayResizingColsOption={true}
          downloadAllCols={false}
          inputDataSource={inputDataSource}
          label="Cluster Configuration Summary"
          sizeColumnsToFitInitially={true}
          tooltipShowDelay={0}
        />
      )}
    </PaddedAndRaisedSegment>
  );
};
