// @flow
import { LinkRenderer } from '@components/views/grid-utils/renderers';
import React from 'react';
import { highlightMatchingPortionInTextAndReturnSpan } from '@components/home-page/hierarchy-view/utils';
import { getLinkForOrganizationsPage } from '@streaming-projects/links';

export const OrganizationNameRenderer = ({ highlightTerm, link, organizationName }) => {
  return (
    <LinkRenderer
      pathName={getLinkForOrganizationsPage(link)}
      text={highlightMatchingPortionInTextAndReturnSpan(organizationName, highlightTerm)}
    />
  );
};
