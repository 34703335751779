// @flow
import { Grid } from 'semantic-ui-react';
import React from 'react';
import { StyledGridRow } from '@src/common-utils/styledComponents';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';

import OrgDetailsTextFormInput from './auxilary-components/OrgDetailsTextFormInput';
import { SPRateCardsContainer } from './auxilary-components/SPRateCardsContainer';

export const SPAccountDetailsGrid = ({ disableOnFormErrors }) => (
  <Grid columns={2} divided={true}>
    <StyledGridRow>
      <Grid.Column>
        {/* Account ID */}
        <OrgDetailsTextFormInput
          configKey={BACKEND_FIELDNAMES.ACCOUNT_ID}
          disableOnFormErrors={disableOnFormErrors}
        />
        {/* Account Name */}
        <OrgDetailsTextFormInput
          configKey={BACKEND_FIELDNAMES.ACCOUNT_NAME}
          disableOnFormErrors={disableOnFormErrors}
        />
      </Grid.Column>

      <Grid.Column>
        {/* ORG Id */}
        <OrgDetailsTextFormInput
          configKey={BACKEND_FIELDNAMES.SFDC_ORG_ID}
          disableOnFormErrors={disableOnFormErrors}
        />
        {/* ORG Name */}
        <OrgDetailsTextFormInput
          configKey={BACKEND_FIELDNAMES.SFDC_ORG_NAME}
          disableOnFormErrors={disableOnFormErrors}
        />
      </Grid.Column>
    </StyledGridRow>

    <StyledGridRow>
      <Grid.Column>
        {/* Rate Card Input */}
        <SPRateCardsContainer disableOnFormErrors={disableOnFormErrors} />
      </Grid.Column>
    </StyledGridRow>
  </Grid>
);
