// @flow
import React from 'react';
import { getRCVersionOptionsFromRCs } from '@streaming-projects/utils';
import { SelectField } from '@src/formik-utils/FormFields';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';

import { SP_COMMIT_CONFIGS, STREAMING_PROJECTS_ORG_DETAILS_CONFIG_MAP } from '../config';

export const SPRateCardsContainer = ({ disableOnFormErrors }) => {
  const rcVersions = SP_COMMIT_CONFIGS.rate_cards;
  const rcVersionsOptions = getRCVersionOptionsFromRCs(rcVersions, false);
  const { backendFieldName, displayName } = STREAMING_PROJECTS_ORG_DETAILS_CONFIG_MAP.get(
    BACKEND_FIELDNAMES.RATE_CARD
  );

  return (
    <SelectField
      disableOnFormErrors={disableOnFormErrors}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      options={rcVersionsOptions}
    />
  );
};
