// @flow
export const LINK = 'link';
export const BASELINE_SP_IDENTIFIER_NAME = 'Baseline';

export const BACKEND_FIELDNAMES = {
  NAME: 'name',
  ID: 'id',
  LINK: 'link',
  SFDC_ORG_NAME: 'sfdc_org_name',
  SFDC_ORG_ID: 'sfdc_org_id',
  RATE_CARD: 'rate_card',
  ACCOUNT_NAME: 'account_name',
  ACCOUNT_ID: 'account_id',
  SP_NAME: 'name',
  SP_SFDC_ID: 'spSfdcId',
  SP_START_DATE: 'start_date',
  SP_END_DATE: 'end_date',
};

export const FRONT_END_DISPLAY_NAMES = {
  SP_NAME: 'SP Name',
  SP_ID: 'Streaming Project ID',
  SP_START_DATE: 'Start Date',
  SP_END_DATE: 'End Date',
};
