// @flow
import React from 'react';
import { DeleteRenderer } from '@components/views/grid-utils/renderers';

import { SPDeleteWarningModal } from './SPDeleteWarningModal';

export const SPDeleteRenderer = ({ data: { id, name, version } }) => {
  return (
    <DeleteRenderer>
      {(isDeleteModalOpen, setDeleteModalOpen) => {
        return (
          <SPDeleteWarningModal
            isOpen={isDeleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            spId={id}
            spName={name}
            version={version}
          />
        );
      }}
    </DeleteRenderer>
  );
};
