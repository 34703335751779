// @flow
import React from 'react';
import { StyledLabel } from '@src/common-utils/styledComponents';

export const ResourceEditRepriceWarningContainer = ({ resourceType }) => (
  <StyledLabel style={{ color: 'red' }}>
    Note: All Streaming Projects on this {resourceType} will have to be repriced adding considerable
    load to our infrastructure. Use with caution!
  </StyledLabel>
);
