// @flow
import React from 'react';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { STREAMING_PROJECTS_SP_DETAILS_CONFIG_MAP } from '@streaming-projects/sp-page/config';

import { TextField } from '../../../formik-utils/FormFields';

export const SPSPNameContainer = ({ disableOnFormErrors = null }) => {
  const { backendFieldName, displayName, icon } = STREAMING_PROJECTS_SP_DETAILS_CONFIG_MAP.get(
    BACKEND_FIELDNAMES.SP_NAME
  );
  return (
    <TextField
      disableOnFormErrors={disableOnFormErrors}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      fluid={true}
      icon={icon}
    />
  );
};
