// @flow
import React from 'react';

import { Spacer } from './components/presentational/spacing/Spacer';

// General
export const YES = 'YES';
export const NO = 'NO';
export const ALL = 'ALL';
export const ALL_IN_SMALL_CASE = 'all';
export const ENABLE = 'Enable';
export const ENABLED = 'Enabled';
export const DISABLED = 'Disabled';
export const HEADER_SUFFIX_FOR_DOLLAR_COLUMNS = '(in $)';
export const HEADER_SUFFIX_FOR_MARGIN_COLUMNS = '(in %)';
export const TOTAL_COMMITMENT = 'Total Commitment';
export const ESTIMATE_CUSTOMER_VIEW_ENABLED_BACKEND_NAME = 'customer_view_enabled';
export const ESTIMATE_CUSTOMER_VIEW_ENABLED_DISPLAY_NAME = 'Customer View';
export const DEFAULT_CONFLICTING_UPDATE_ERROR_MESSAGE =
  'There has been a conflict in your request! Please check!';

export const CONFLICTING_UPDATE_ERROR_MESSAGE = (
  <>
    <p>
      The Estimate has changed since you last retrieved it, please <Spacer x={2} />
      <mark>
        <strong>refresh</strong>
      </mark>
      <Spacer x={2} /> your browser to see the latest version and try again!
    </p>
  </>
);

export const DEFAULT_FE_ERROR_MESSAGE =
  'An error occurred, please contact #cloud-commitment-estimator channel!';

export const ERROR_MESSAGES_MAPPING_FOR_409 = {
  'Estimate Version Is Outdated': CONFLICTING_UPDATE_ERROR_MESSAGE,
};

// FieldInputGrid Related
export const IS_ROW_OPENED_FOR_EDITING = 'isRowOpenedForEditing';
export const IS_ROW_FROZEN = 'isRowFrozen';
export const DB_ROW_ID = 'dbRowId';
export const ROW_ID = 'id';
export const DEFAULT_VALUE_FOR_DB_ROW_ID = -1;
export const DEFAULT_VALUE_FOR_DB_ROW_ID_STR = `${DEFAULT_VALUE_FOR_DB_ROW_ID}`;

// Home Page Related
export const HIERARCHY_LEVELS_TO_ICON_COLORS_MAP = new Map([
  ['Account', 'blue'],
  ['Organization', 'teal'],
  ['Opportunity', 'grey'],
]);
export const DEFAULT_NUMBER_OF_ACCOUNTS_TO_SHOW_ON_HOME_PAGE = 10;
export const MAXIMUM_NUMBER_OF_ACCOUNTS_PER_PAGE_ON_HOME_PAGE = 10;
export const LIMIT_INDICATOR_FOR_FETCH_ALL_ON_HOME_PAGE = -1;

// Estimate Details Related
export const ESTIMATE_NAME_DISPLAY_NAME = 'Estimate Name';
export const ESTIMATE_NAME_BACKEND_NAME = 'name';
export const SFDC_ACCOUNT_ID_DISPLAY_NAME = 'Account ID';
export const SFDC_ACCOUNT_ID_BACKEND_NAME = 'salesforce_account_id';
export const SFDC_ACCOUNT_NAME_DISPLAY_NAME = 'Account Name';
export const SFDC_ACCOUNT_NAME_BACKEND_NAME = 'salesforce_account_name';
export const SFDC_ORGANIZATION_ID_DISPLAY_NAME = 'Organization ID';
export const SFDC_ORGANIZATION_ID_BACKEND_NAME = 'org_id';
export const SFDC_ORGANISATION_NAME_DISPLAY_NAME = 'Organization Name';
export const SFDC_ORGANISATION_NAME_BACKEND_NAME = 'org_name';
export const SFDC_OPPORTUNITY_ID_DISPLAY_NAME = 'Opportunity ID';
export const SFDC_OPPORTUNITY_ID_BACKEND_NAME = 'opportunity_id';
export const SFDC_OPPORTUNITY_NAME_DISPLAY_NAME = 'Opportunity Name';
export const SFDC_OPPORTUNITY_NAME_BACKEND_NAME = 'opportunity_name';
export const SFDC_QUOTE_NAME_DISPLAY_NAME = 'SFDC Quote Name';
export const SFDC_QUOTE_NAME_BACKEND_NAME = 'quote_name';
export const ESTIMATE_VERSION_BACKEND_NAME = 'estimate_version';

// Estimate Commit Related
export const MARKETPLACE_DISPLAY_NAME = 'Marketplace';
export const MARKETPLACE_BACKEND_NAME = 'marketplace';
export const MARKETPLACE_NONE = 'None';
export const PAYMENT_SCHEDULE_DISPLAY_NAME = 'Payment Schedule';
export const PAYMENT_SCHEDULE_BACKEND_NAME = 'payment_schedule';
export const PAYMENT_SCHEDULE_PREPAID_BACKEND_NAME = 'Prepaid';
export const PAYMENT_SCHEDULE_PREPAID_DISPLAY_NAME = 'Prepaid';
export const PAYMENT_SCHEDULE_ARREARS_BACKEND_NAME = 'Arrears';
export const PAYMENT_SCHEDULE_ARREARS_DISPLAY_NAME = 'Payable in Arrears';
export const RATE_CARD_DISPLAY_NAME = 'Rate Card';
export const RATE_CARD_BACKEND_NAME = 'rate_card';
export const SUPPORT_TIER_DISPLAY_NAME = 'Support Tier';
export const SUPPORT_TIER_BACKEND_NAME = 'support_tier';
export const DEAL_TYPE_DISPLAY_NAME = 'Deal Type';
export const DEAL_TYPE_BACKEND_NAME = 'deal_type';
export const DEAL_TYPE_RENEWAL = 'Renewal';
export const AUG_1_RATE_CARD_VERSION = '8/1';
export const RATE_CARD_VERSION_4_11_2024 = '4/11/2024';
export const GLOBAL_DISCOUNT_APPLIED_DISPLAY_NAME = 'Global Discount Applied';
export const GLOBAL_DISCOUNT = 'Global Discount';
export const GLOBAL_DISCOUNT_APPLIED_BACKEND_NAME = 'global_discount';
export const DEAL_START_DATE_DISPLAY_NAME = 'Deal Start Date';
export const DEAL_START_DATE_BACKEND_NAME = 'deal_start_date';
export const DEAL_END_DATE_DISPLAY_NAME = 'Deal End Date';
export const DEAL_END_DATE_BACKEND_NAME = 'deal_end_date';
export const CUSTOM_DISCOUNTS_ENABLED_BACKEND_NAME = 'custom_discount_enabled';
export const CUSTOM_DISCOUNTS_ENABLED_DISPLAY_NAME = 'Custom Discounts';
export const CREDITS_AND_BURSTS_ENABLED_BACKEND_NAME = 'cnbc_enabled';
export const CREDITS_AND_BURSTS_ENABLED_DISPLAY_NAME = 'Credits and Bursts';

// Stream Governance Related

// Stream Governance Related
export const STREAM_GOVERNANCE_JSON_BACKEND_NAME = 'stream_governance_json';
export const STREAM_GOVERNANCE_CONFIG_ARRAY_BACKEND_NAME = 'stream_governance_config_array';
export const STREAM_GOVERNANCE_DB_ROW_ID_BACKEND_NAME = 'id';
export const STREAM_GOVERNANCE_ENV_BACKEND_NAME = 'environment_name';
export const STREAM_GOVERNANCE_PACKAGE_BACKEND_NAME = 'package';
export const STREAM_GOVERNANCE_CLOUD_PROVIDER_BACKEND_NAME = 'provider_name';
export const STREAM_GOVERNANCE_CLOUD_PROVIDER_LABEL_BACKEND_NAME = 'provider_label';
export const STREAM_GOVERNANCE_CLOUD_PROVIDER_REGION_BACKEND_NAME = 'region_name';
export const STREAM_GOVERNANCE_CLOUD_PROVIDER_REGION_LABEL_BACKEND_NAME = 'region_label';
export const STREAM_GOVERNANCE_NUMBER_OF_SCHEMAS_BACKEND_NAME = 'schema_count';
export const STREAM_GOVERNANCE_NUMBER_OF_RULES_BACKEND_NAME = 'rules_count';
export const STREAM_GOVERNANCE = 'Stream Governance';
export const STREAM_GOVERNANCE_SUMMARY = 'Stream Governance Summary';
export const STREAM_GOVERNANCE_ESSENTIALS_PACKAGE_NAME = 'ESSENTIALS';
export const STREAM_GOVERNANCE_ADVANCED_PACKAGE_NAME = 'ADVANCED';
export const STREAM_GOVERNANCE_SUMMARY_GRID_NAME = 'Name';
export const STREAM_GOVERNANCE_SUMMARY_GRID_ENABLED = 'Enabled';

// Estimate Summary Related
export const ESTIMATE_SUMMARY = 'Estimate Summary';
export const ESTIMATE_COMMIT_SUMMARY = 'Commit Summary';
export const ESTIMATE_SFDC_FIELDS_SUMMARY = 'SFDC Fields to Populate';
export const ESTIMATE_ANNUAL_SUMMARIES = 'Annual Summaries';
export const TCO_SUMMARY = 'TCO Summary';
export const ESTIMATE_CLUSTER_PRICING_SUMMARY = 'Cluster Pricing Summary';
export const ESTIMATE_CLUSTER_CONFIGURATION_SUMMARY = 'Cluster Configurations';
export const ESTIMATE_STREAM_GOVERNANCE_PER_ENV_SPEND_SUMMARY = 'Stream Governance Spend Summary';
export const ESTIMATE_STREAM_GOVERNANCE_MONTHLY_SPEND_SUMMARY =
  'Stream Governance Monthly Spend Summary';
export const ESTIMATE_CLOUD_MONTHLY_SPEND_SUMMARY = 'Cloud Monthly Spend Summary';
export const ESTIMATE_MONTHLY_SPEND_SUMMARY = 'Monthly Spend Summary';
export const ESTIMATE_FACT_TABLE_SUMMARY = 'Estimate Custom View';

// Custom Discounts Related
export const CUSTOM_DISCOUNTS_JSON_BACKEND_NAME = 'custom_discounts_json';
export const GLOBAL_DISCOUNT_BACKEND_NAME = 'global_discount';
export const CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_BACKEND_NAME = `${CUSTOM_DISCOUNTS_JSON_BACKEND_NAME}.${GLOBAL_DISCOUNT_BACKEND_NAME}`;
export const CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_BACKEND_NAME_ORIGINAL = `${CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_BACKEND_NAME}_original`;
export const GLOBAL_DISCOUNT_IS_FROZEN_BACKEND_NAME = 'global_discount_isFrozen';
export const CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_IS_FROZEN_BACKEND_NAME = `${CUSTOM_DISCOUNTS_JSON_BACKEND_NAME}.${GLOBAL_DISCOUNT_IS_FROZEN_BACKEND_NAME}`;
export const METRICS_LEVEL_DISCOUNTS_CONFIG_ARRAY_BACKEND_NAME = 'metrics_level_discounts_array';
export const METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_ARRAY_BACKEND_NAME =
  'dimensions_config_array';
export const METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_NAME_BACKEND_NAME = 'metricName';
export const METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_ID_BACKEND_NAME = 'metricId';
export const METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_DISCOUNT_BACKEND_NAME = 'discount';
// eslint-disable-next-line id-length
export const METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_DIMENSION_VALUES_BACKEND_NAME =
  'dimensionValues';

export const METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_MLD_ID_BACKEND_NAME = 'metric_discount_id';

// Credits and Bursts Related
export const CREDITS_AND_BURSTS_JSON_BACKEND_NAME = 'credits_and_bursts_json';
export const CREDITS_AND_BURSTS_CONFIG_ARRAY_BACKEND_NAME = 'cnbc_configs';
export const CREDITS_AND_BURSTS_YEAR_NUMBER_BACKEND_NAME = 'year_number';
export const CREDITS_AND_BURSTS_CREDITS_AMOUNT_BACKEND_NAME = 'credit_amount';
export const CREDITS_AND_BURSTS_BURSTS_AMOUNT_BACKEND_NAME = 'burst_amount';
export const CREDITS_AND_BURSTS_MAX_CONFIGURABLE_LIMIT = 1000000000;

// Clusters Related
export const CLUSTER_ID = 'Cluster ID';
export const CLUSTER_ID_BACKEND_NAME = 'id';
export const CLUSTER_NAME = 'Cluster Name';
export const NAME = 'Name';
export const CLUSTER_NAME_BACKEND_NAME = 'name';
export const TYPE = 'Type';
export const CLUSTER_TYPE = 'Cluster Type';
export const CLUSTER_TYPE_BACKEND_NAME = 'cluster_type';
export const CLUSTER_TYPE_LABEL_BACKEND_NAME = 'cluster_type_label';
export const CLUSTER_NETWORKING = 'Networking';
export const CLUSTER_NETWORKING_TYPE = 'Networking Type';
export const CLUSTER_NETWORKING_TYPE_BACKEND_NAME = 'networking_type';
export const CLUSTER_NETWORKING_TYPE_LABEL_BACKEND_NAME = 'networking_type_label';
export const CLUSTER_PROVIDER = 'Provider';
export const CLUSTER_PROVIDER_BACKEND_NAME = 'provider';
export const CLUSTER_PROVIDER_LABEL_BACKEND_NAME = 'provider_label';
export const CLUSTER_REGION = 'Region';
export const CLUSTER_REGION_BACKEND_NAME = 'region';
export const CLUSTER_REGION_LABEL_BACKEND_NAME = 'region_label';
export const CLUSTER_AVAILABILITY = 'Availability';
export const CLUSTER_DEPLOYMENT = 'Deployment';
export const CLUSTER_AVAILABILITY_IN_SMALL_CASE = 'availability';
export const CLUSTER_AVAILABILITY_BACKEND_NAME = 'az_configuration';
export const CLUSTER_AVAILABILITY_LABEL_BACKEND_NAME = 'az_config_label';
export const CLUSTER_ENABLED = 'Enabled';

export const CLUSTER_ENABLED_BACKEND_NAME = 'isEnabled';
export const CLUSTER_PARTITIONS = 'Partitions';
export const CLUSTER_PARTITIONS_BACKEND_NAME = 'partition_count';
export const CLUSTER_EXISTING_STORAGE_DISPLAY_NAME = 'Existing Storage After Replication( GB )';
export const CLUSTER_EXISTING_STORAGE_BACKEND_NAME = 'existing_storage';
export const CLUSTER_RETENTION = 'Retention (days)';
export const CLUSTER_RETENTION_BACKEND_NAME = 'retention_days';
export const CLUSTER_RETENTION_INFINITE = 'Infinite Retention';
export const CLUSTER_RETENTION_INFINITE_BACKEND_NAME = 'retention_infinite';
export const CLUSTER_TOTAL_SPEND_AT_LIST = 'Total Spend (List)';
export const CLUSTER_TOTAL_SPEND_AT_LIST_BACKEND_NAME = 'total_spend';
export const CLUSTER_TOTAL_SPEND_DISCOUNTED = 'Total Spend (Disc)';
export const CLUSTER_TOTAL_SPEND_DISCOUNTED_BACKEND_NAME = 'total_spend_discounted';
export const CLUSTER_FOLLOWER_FETCH = 'Follow-Fetch (available on VPC 2.0 only)';
export const CLUSTER_FOLLOWER_FETCH_BACKEND_NAME = 'is_follower_fetch_enabled';
export const CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG = 'throughput';
export const CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG = 'ksqldb';
export const CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG = 'cluster_linking';
export const CLUSTER_AVAILABLE_CONNECTORS_LIST = 'Available Connectors';
export const CLUSTER_AVAILABLE_CONNECTORS_LIST_BACKEND_NAME = 'connector_name';
export const CONNECTOR_LABEL = 'connector_label';
export const CONNECTOR_AVAILABLE_NETWORKING_TYPES_LIST = 'Connector Networking Type';
export const DEFAULT_VALUE_FOR_CONNECTOR_NETWORKING_TYPE = 'connect_public';
export const CONNECTOR_ALLOWED_NETWORKING_TYPES = [
  'connect_public',
  'connect_peering',
  'connect_private_link',
];
export const CONNECTOR_AVAILABLE_NETWORKING_TYPES_LIST_BACKEND_NAME =
  'available_connector_networking_type';
export const CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG = 'connectors';
export const CLUSTER_SLA_DISPLAY_NAME = 'SLA';
export const CLUSTER_SLA_BACKEND_NAME = 'sla';
export const CLUSTER_SLA_DISPLAY_COL_MAPPING = 'sla_label';

// Estimate Form Names
export const ESTIMATE_ACCOUNT_DETAILS_FORM = 'Estimate Account Details Form';
export const ESTIMATE_COMMIT_DETAILS_FORM = 'Estimate Commit Details Form';
export const ESTIMATE_TCO_DETAILS_FORM = 'Estimate TCO Details Form';
export const ESTIMATE_CUSTOM_DISCOUNTS_DETAILS_FORM = 'Estimate Custom Discounts Details Form';
export const ESTIMATE_CNBC_DETAILS_FORM = 'Estimate CNBC Details Form';

// Cluster Form Names
export const CLUSTER_DETAILS_FORM = 'CLUSTER_DETAILS_FORM';
export const CLUSTER_KAFKA_FORM = 'CLUSTER_KAFKA_FORM';
export const CLUSTER_KSQLDB_FORM = 'CLUSTER_KSQLDB_FORM';
export const CLUSTER_CONNECTORS_FORM = 'CLUSTER_CONNECTORS_FORM';
export const CLUSTER_CLUSTER_LINKING_FORM = 'CLUSTER_CLUSTER_LINKING_FORM';
export const CLUSTER_TCO_INPUT_FORM = 'CLUSTER_TCO_INPUT_FORM';
export const CLUSTER_DEDICATED_SURVEY_INPUT_FORM = 'CLUSTER_DEDICATED_SURVEY_INPUT_FORM';

// Cluster Types
export const BASIC_CLUSTER_TYPE = 'basic';
export const STANDARD_CLUSTER_TYPE = 'standard';
export const ENTERPRISE_CLUSTER_TYPE = 'enterprise';
export const DEDICATED_CLUSTER_TYPE = 'dedicated';
export const BASIC_V2_CLUSTER_TYPE = 'basic_v2';
export const STANDARD_V2_CLUSTER_TYPE = 'standard_v2';

// Cluster Providers
export const AWS = 'aws';
export const AZURE = 'azure';
export const GCP = 'gcp';

// Cluster Availability
export const SZ = 'sz';
export const MZ = 'mz';

// Cluster Networking Types
export const INTERNET = 'internet';
export const PEERED_VPC = 'peered_vpc';
export const PRIVATE_LINK = 'private_link';
export const TRANSIT_GATEWAY = 'transit_gateway';

// Cluster Partition Limits

export const BASIC_CLUSTER_PARTITION_LIMIT = 4096;
export const STANDARD_CLUSTER_PARTITION_LIMIT = 4096;
export const ENTERPRISE_CLUSTER_PARTITION_LIMIT = 3000 * 10;
export const DEDICATED_CLUSTER_PARTITION_LIMIT = 100000;

export const CLUSTER_PARTITIONS_LIMITS_MAP = new Map([
  [BASIC_CLUSTER_TYPE, BASIC_CLUSTER_PARTITION_LIMIT],
  [STANDARD_CLUSTER_TYPE, STANDARD_CLUSTER_PARTITION_LIMIT],
  [ENTERPRISE_CLUSTER_TYPE, ENTERPRISE_CLUSTER_PARTITION_LIMIT],
  [DEDICATED_CLUSTER_TYPE, DEDICATED_CLUSTER_PARTITION_LIMIT],
]);

export const MAX_INPUT_VALUE_FOR_ANY_FIELD = 10000000;

export const CLUSTER_WRITE_THROUGHPUT_LIMITS_MAP = new Map([
  [BASIC_CLUSTER_TYPE, 250],
  [STANDARD_CLUSTER_TYPE, 250],
  [ENTERPRISE_CLUSTER_TYPE, 600],
  [DEDICATED_CLUSTER_TYPE, MAX_INPUT_VALUE_FOR_ANY_FIELD],
]);

export const CLUSTER_READ_THROUGHPUT_LIMITS_MAP = new Map([
  [BASIC_CLUSTER_TYPE, 750],
  [STANDARD_CLUSTER_TYPE, 750],
  [ENTERPRISE_CLUSTER_TYPE, 1800],
  [DEDICATED_CLUSTER_TYPE, MAX_INPUT_VALUE_FOR_ANY_FIELD],
]);

export const CLUSTER_CONNECTED_CLIENTS_COUNT_LIMITS_MAP = new Map([
  [ENTERPRISE_CLUSTER_TYPE, 4500 * 10],
  [DEDICATED_CLUSTER_TYPE, MAX_INPUT_VALUE_FOR_ANY_FIELD],
]);

export const CLUSTER_CLIENT_CONNECTION_ATTEMPTS_LIMITS_MAP = new Map([
  [ENTERPRISE_CLUSTER_TYPE, 250 * 10],
  [DEDICATED_CLUSTER_TYPE, MAX_INPUT_VALUE_FOR_ANY_FIELD],
]);

export const CLUSTER_CLIENT_REQUESTS_LIMITS_MAP = new Map([
  [ENTERPRISE_CLUSTER_TYPE, 7500 * 10],
  [DEDICATED_CLUSTER_TYPE, MAX_INPUT_VALUE_FOR_ANY_FIELD],
]);

// Theming
export const DEFAULT_BACKGROUND_COLOR_FOR_LIGHT_THEME = '#FFFFFF';
export const DEFAULT_BACKGROUND_COLOR_FOR_DARK_THEME = '#111517';
export const THEME_LIGHT = 'light';
export const THEME_DARK = 'dark';

// Flink Pool Related
export const FLINK_POOL_DETAILS_FORM = 'FLINK_POOL_DETAILS_FORM';
export const FLINK_POOL_MONTHLY_INPUTS_FORM = 'FLINK_POOL_MONTHLY_INPUTS_FORM';
export const FLINK_POOL_ID = 'Flink Pool ID';
export const FLINK_POOL_ID_BACKEND_NAME = 'id';
export const FLINK_POOL_NAME = 'Pool Name';
export const FLINK_POOL_NAME_BACKEND_NAME = 'name';
export const FLINK_POOL_ENABLED = 'Enabled';
export const FLINK_POOL_ENABLED_BACKEND_NAME = 'is_enabled';
export const FLINK_POOL_ENABLED_BACKEND_NAME_FOR_SUMMARY = 'isEnabled';
export const FLINK_POOL_PROVIDER = 'Provider';
export const FLINK_POOL_PROVIDER_BACKEND_NAME = 'provider';
export const FLINK_POOL_PROVIDER_LABEL_BACKEND_NAME = 'provider_label';
export const FLINK_POOL_REGION = 'Region';
export const FLINK_POOL_REGION_BACKEND_NAME = 'region';
export const FLINK_POOL_REGION_LABEL_BACKEND_NAME = 'region_label';
export const FLINK_POOL_TOTAL_SPEND_AT_LIST = 'Total Spend (List)';
export const FLINK_POOL_TOTAL_SPEND_AT_LIST_BACKEND_NAME = 'total_spend';
export const FLINK_POOL_TOTAL_SPEND_DISCOUNTED = 'Total Spend (Disc)';
export const FLINK_POOL_TOTAL_SPEND_DISCOUNTED_BACKEND_NAME = 'total_spend_discounted';
export const FLINK_POOL_MONTHLY_INPUTS_JSON_CONFIG = 'flink_pool_monthly_inputs';
export const FLINK_POOL_PRICING_SUMMARY = 'Flink Pool Pricing Summary';
export const FLINK_POOL_CONFIGURATION_SUMMARY = 'Flink Pool Configuration';

// TCO Details

export const ESTIMATE_LEVEL_TCO_INPUTS_SECTION_HEADER = 'TCO Details';
export const TCO_CSP_DISCOUNT_APPLIED_DISPLAY_NAME = 'CSP Discount Applied';
export const TCO_CSP_DISCOUNT_APPLIED_BACKEND_FIELD_NAME = 'csp_discount';
export const TCO_COST_PER_BUSINESS_DOWNTIME_HOUR_DISPLAY_NAME = 'Cost Per Business Downtime Hour';
export const TCO_COST_PER_BUSINESS_DOWNTIME_HOUR_BACKEND_FIELD_NAME = 'downtime_hourly_cost';
export const TCO_OVER_PROVISION_PERCENTAGE_DISPLAY_NAME = 'Over Provision Percentage';
export const TCO_OVER_PROVISION_PERCENTAGE_BACKEND_FIELD_NAME = 'overprovision_percent';
export const TCO_STORAGE_UTILIZATION_BUFFER_DISPLAY_NAME = 'Storage Utilisation Buffer';
export const TCO_STORAGE_UTILIZATION_BUFFER_BACKEND_FIELD_NAME = 'storage_utilization_buffer';
export const TCO_REGION_DISPLAY_NAME = 'Region';
export const TCO_REGION_BACKEND_FIELD_NAME = 'region';
export const TCO_AVAILABLE_REGIONS = 'TCO_AVAILABLE_REGIONS';
export const TCO_INPUTS_JSON_BACKEND_NAME = 'tco_inputs';
export const TCO_CLUSTER_MISSION_CRITICAL_DISPLAY_NAME = 'Mission Critical';
export const TCO_CLUSTER_MISSION_CRITICAL_BACKEND_FIELD_NAME = 'mission_critical';
export const TCO_CLUSTER_FOLLOWER_FETCH_DISPLAY_NAME = 'Fetch From Follower';
export const TCO_CLUSTER_FOLLOWER_FETCH_BACKEND_FIELD_NAME = 'fetch_from_follower';
export const TCO_CLUSTER_TIERED_STORAGE_DISPLAY_NAME = 'Tiered Storage';
export const TCO_CLUSTER_TIERED_STORAGE_BACKEND_FIELD_NAME = 'tiered_storage';
export const TCO_CLUSTER_LOCAL_RETENTION_DISPLAY_NAME = 'Local Retention ( hours )';
export const TCO_CLUSTER_LOCAL_RETENTION_BACKEND_FIELD_NAME = 'local_retention';
export const TCO_VENDOR_MSK = 'MSK';
export const TCO_VENDOR_OSK = 'OSK';
export const TCO_VENDOR_CONFLUENT = 'CONFLUENT';
export const TCO_AVAILABLE_PROVIDERS_LIST = [TCO_VENDOR_OSK, TCO_VENDOR_MSK, TCO_VENDOR_CONFLUENT];
export const TCO_MSK_COMPUTE = 'MSK Compute';
export const TCO_MSK_NETWORK = 'MSK Network';
export const TCO_MSK_STORAGE = 'MSK Storage';
export const TCO_OSK_COMPUTE = 'OSK Compute';
export const TCO_OSK_NETWORK = 'OSK Network';
export const TCO_OSK_STORAGE = 'OSK Storage';
export const TCO_INFRASTRUCTURE = 'Infrastructure';
export const TCO_OPERATING_EXPENSES = 'Operating Expenses';
export const TCO_OPERATIONS = 'Operations';
export const TCO_SUPPORT = 'Support';
export const TCO_RISK = 'Risk';

// Stop Selling Dedicated Related

export const DEDICATED_SURVEY_INPUTS_JSON_BACKEND_FIELD_NAME_FOR_UPDATE = 'survey_inputs';
export const DEDICATED_SURVEY_INPUTS_RENEWAL_DISPLAY_NAME = 'Renewal';
export const DEDICATED_SURVEY_INPUTS_RENEWAL_BACKEND_FIELD_NAME = 'renewal';
export const DEDICATED_SURVEY_INPUTS_THEATRE_DISPLAY_NAME = 'Theatre';
export const DEDICATED_SURVEY_INPUTS_THEATRE_BACKEND_FIELD_NAME = 'theatre';
export const DEDICATED_SURVEY_INPUTS_INDUSTRY_DISPLAY_NAME = 'Industry';
export const DEDICATED_SURVEY_INPUTS_INDUSTRY_BACKEND_FIELD_NAME = 'industry';
export const DEDICATED_SURVEY_INPUTS_REASON_DISPLAY_NAME =
  'Why is the customer not using Enterprise Clusters?';
export const DEDICATED_SURVEY_INPUTS_REASON_BACKEND_FIELD_NAME = 'reasons';
export const DEDICATED_SURVEY_INPUTS_OTHER_REASON_DISPLAY_NAME = 'Other Reason';
export const DEDICATED_SURVEY_INPUTS_OTHER_REASON_BACKEND_FIELD_NAME = 'other';
export const DEDICATED_SURVEY_INPUTS_OTHER_REASON_BACKEND_NAME = 'Other';

// Feature Flags
export const IS_SFDC_INTEGRATION_LIVE = true;

export const SP_COMMIT_CONFIGS = {
  deal_types: ['New Commit', 'Renewal'],
  marketplaces: ['None', 'AWS', 'GCP', 'Azure'],
  payment_schedules: ['Prepaid', 'Arrears'],
  support_tiers: ['Free', 'Developer', 'Business', 'Premier'],
  rate_cards: [
    {
      id: 1,
      name: '8/1',
      label: 'Aug 1, 2020 (8/1) (Admins Only)',
      actionable: true,
    },
    {
      id: 2,
      name: '10/1',
      label: 'Oct 1, 2020 (10/1)',
      actionable: true,
    },
    {
      id: 3,
      name: '3/5/2024',
      label: 'Mar 5, 2024 (3/5/2024)',
      actionable: true,
    },
    {
      id: 4,
      name: '4/11/2024',
      label: 'Apr 11, 2024 (4/11/2024)',
      actionable: true,
    },
  ],
};
