// @flow

import React from 'react';
import {
  getHierarchyResultSetInfoMessage,
  getIfShowingPartialResults,
} from '@components/home-page/hierarchy-view/utils';
import { StyledH4 } from '@presentational/headings/StyledH4';
import { Spacer } from '@presentational/spacing/Spacer';
import { FetchAllButton } from '@components/home-page/hierarchy-view/FetchAllButton';
import { LIMIT_INDICATOR_FOR_FETCH_ALL } from '@streaming-projects/constants';
import { If } from '@presentational/If';
import { HierarchicalComponent } from '@presentational/hierarchical/HierarchicalComponent';

import { SPOrganizationsGrid } from './SPOrganizationsGrid';

export const SPHierarchy = ({ accounts, limit = null, searchTerm = '', onLimitChange }) => {
  const resultSetInfoMessage = getHierarchyResultSetInfoMessage(limit, searchTerm !== '');
  const areWeShowingPartialResults = getIfShowingPartialResults(searchTerm, limit);
  const hierarchyLevels = [{ name: 'Account', folderIcon: 'folder', color: 'blue' }];

  return (
    <>
      <StyledH4 inline={true} text={resultSetInfoMessage} />
      <Spacer x={10} />
      <If test={areWeShowingPartialResults}>
        <FetchAllButton
          onClick={() => {
            onLimitChange(LIMIT_INDICATOR_FOR_FETCH_ALL);
          }}
        />
      </If>

      <Spacer y={40} />
      <HierarchicalComponent
        LeafComponent={SPOrganizationsGrid}
        data={accounts}
        hierarchyLevels={hierarchyLevels}
        highlightTerm={searchTerm}
      />
    </>
  );
};
