// @flow
import { Icon } from 'semantic-ui-react';
import React from 'react';

import { StyledLabel, StyledSpan } from '../../../common-utils/styledComponents';
import { Spacer } from '../spacing/Spacer';

export const Legend = ({ hierarchyLevels }) => {
  return (
    <>
      <StyledLabel>Legend:</StyledLabel>
      <Spacer x={12} />
      {hierarchyLevels.map((level) => (
        <>
          <Icon color={level.color} name={level.folderIcon} />
          <StyledSpan>{level.name}</StyledSpan>
          <Spacer x={12} />
        </>
      ))}
    </>
  );
};
