// @flow

import React from 'react';
import { getAccordianPanelDetailsObject } from '@src/common-utils/utils';
import { AccordionsList } from '@presentational/accordions/AccordionsList';
import { SPOrganizationDetailsContainer } from '@streaming-projects/orgs/org-top-level/SPOrganizationDetailsContainer';
import SPResourceConfigurations from '@streaming-projects/resource-definitions/SPResourceConfigurationsTopLevelContainer';
import { StreamingProjectsSummaryContainer } from '@streaming-projects/orgs/org-top-level/sp-summary/StreamingProjectsSummaryContainer';

export const SPOrgAccordionsContainer = () => {
  const accordionPanels = [
    getAccordianPanelDetailsObject('Org Details', <SPOrganizationDetailsContainer />),
    getAccordianPanelDetailsObject(
      'Streaming Projects Configurations',
      <StreamingProjectsSummaryContainer />
    ),
    getAccordianPanelDetailsObject('Resource Configurations', <SPResourceConfigurations />),
  ];

  return (
    <>
      <AccordionsList panels={accordionPanels} />
    </>
  );
};
