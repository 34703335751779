// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG_MAP } from '@streaming-projects/resource-definitions/cluster-resource-definitions/cluster-resource-inputs-config';
import {
  CLUSTER_NETWORKING_TYPE_BACKEND_NAME,
  INTERNET,
  PEERED_VPC,
  PRIVATE_LINK,
  TRANSIT_GATEWAY,
} from '@src/constants';
import { getDropdownOptions } from '@components/stream-governance/utils';
import { SelectField } from '@src/formik-utils/FormFields';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';

export const SPClusterNetworkingTypeContainer = ({
  disabled,
  fluid = null,
  disableOnFormErrors = null,
}) => {
  const { values } = useFormikContext();
  const clusterNetworkingTypeConfig = CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG_MAP.get(
    CLUSTER_NETWORKING_TYPE_BACKEND_NAME
  );

  const dataUniverse =
    useContext(SPMetaDataContext).metaData.clusterResourceInputConfigs.cluster_params;

  const clusterNetworkingTypeOptions = getDropdownOptions(
    dataUniverse,
    values,
    clusterNetworkingTypeConfig,
    false,
    true,
    false,
    false,
    [INTERNET, PEERED_VPC, TRANSIT_GATEWAY, PRIVATE_LINK]
  );

  return (
    <SelectField
      disableOnFormErrors={disableOnFormErrors}
      disabled={disabled}
      fieldDisplayName={clusterNetworkingTypeConfig.displayName}
      fieldName={clusterNetworkingTypeConfig.backendFieldName}
      fluid={fluid}
      options={clusterNetworkingTypeOptions}
      placeholder={null}
    />
  );
};
