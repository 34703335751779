// @flow
import React from 'react';
import { number, object } from 'yup';
import { getIn } from 'formik';
import { isPlainObject, isEqual } from 'lodash';
import { DeleteHeaderWithWarning } from '@presentational/DeleteHeaderWithWarning';

import {
  CUSTOM_DISCOUNTS_ENABLED_BACKEND_NAME,
  ESTIMATE_CUSTOMER_VIEW_ENABLED_BACKEND_NAME,
  SFDC_QUOTE_NAME_BACKEND_NAME,
} from '../constants';

import { StyledLabel } from './styledComponents';

export const getIfUserIsAdminAndCustomerViewIsDisabled = (user, estimate) => {
  return (
    getIfUserIsAdminBasedOnGivenDetails(user) &&
    getIfCustomerViewIsDisabledBasedOnGivenEstimateDetails(estimate)
  );
};

export const getIfUserIsAdminBasedOnGivenDetails = (user) => {
  return user?.admin === true;
};

export const getIfCustomerViewIsDisabledBasedOnGivenEstimateDetails = (estimate) =>
  estimate?.inputs?.[ESTIMATE_CUSTOMER_VIEW_ENABLED_BACKEND_NAME] === false;

export const getIfCustomerViewIsEnabled = (values) =>
  values?.[ESTIMATE_CUSTOMER_VIEW_ENABLED_BACKEND_NAME] === true;

export const getIfCustomDiscountsEnabled = (values) =>
  values?.[CUSTOM_DISCOUNTS_ENABLED_BACKEND_NAME] === true;

export const getIfQuoteLineIdIsProvided = (values) =>
  (values?.[SFDC_QUOTE_NAME_BACKEND_NAME] ?? '').length > 0;

export const getIfCustomDiscountsEnabledAndNotInCustomerView = (values) =>
  getIfCustomDiscountsEnabled(values) && !getIfCustomerViewIsEnabled(values);

export const getIfCustomDiscountsEnabledORInCustomerView = (values) =>
  getIfCustomDiscountsEnabled(values) || getIfCustomerViewIsEnabled(values);

export const getTotalCommitmentDollarsDiscountedForTheGivenYear = (estimate, year) => {
  const afterDiscountArray = estimate.annual_summaries.after_discount;
  const estimatedCommitObj = afterDiscountArray.filter(
    (x) => x.field === 'Estimated Commit (Discounted)'
  )[0];

  const yearlyValues = estimatedCommitObj.yearly_values;
  const selectedYearYearlyValues = yearlyValues.filter((x) => x.label === `Year ${year}`);

  // The only way in which the selectedYearYearlyValues array can be empty is when the year is out of range
  // In that case, we dont want this validation to run
  if (selectedYearYearlyValues.length > 0) {
    return Number(selectedYearYearlyValues[0].value);
  } else {
    return Number.POSITIVE_INFINITY;
  }
};

export const getFormattedRateCardVersion = (rateCardVersion) =>
  rateCardVersion ? rateCardVersion.replace('/', '_') : '';

export const getAccordianPanelDetailsObject = (title, component, key = null) => ({
  key: key != null ? key : title.toLowerCase().split(' ').join('-'),
  // When the user provides a title component, use it instead of the default one
  title: {
    content: typeof title === 'string' ? <StyledLabel basic={true} content={title} /> : title,
  },
  content: {
    content: component,
  },
});

export const getInitialValue = (col, inputSource, backendFieldName) => {
  const inputTransformationFunc = col?.inputTransformationFunc;
  const inputComingFromSource = inputSource[backendFieldName];
  let inputToUse = inputComingFromSource;
  if (inputTransformationFunc) {
    inputToUse = inputTransformationFunc(inputComingFromSource);
  }
  return inputToUse;
};

export const getValidation = (col, objectToBePassedInForValidation) => {
  const validationConfigured = col.validation;
  let validationToUse = validationConfigured;
  if (typeof validationConfigured === 'function') {
    validationToUse = validationConfigured(objectToBePassedInForValidation);
  }
  return validationToUse;
};

export const getFormikFormInputsFromColConfigAndInputSource = (
  columnsConfig,
  inputSource,
  objectToBePassedInForValidation
) => {
  const initialValues = {};
  const validationSchemaObj = {};
  const initialTouched = {};

  for (const col of columnsConfig) {
    const backendFieldName = col.backendFieldName;
    initialValues[backendFieldName] = getInitialValue(col, inputSource, backendFieldName);
    validationSchemaObj[backendFieldName] = getValidation(col, objectToBePassedInForValidation);
    if (col.initialTouched) {
      initialTouched[backendFieldName] = true;
    }
  }

  const validationSchema = object(validationSchemaObj);
  return { initialValues, initialTouched, validationSchema };
};

export const convertUTCTimeStampToHumanReadableTime = (updatedAt) => {
  return new Intl.DateTimeFormat('en-US', {
    timeStyle: 'long',
    dateStyle: 'long',
    timeZone: 'UTC',
  }).format(new Date(updatedAt));
};

export const getDropdownOptionsFromArray = (values) => {
  return values.map((x) => ({
    name: x,
    display_name: x,
  }));
};

export const getDropdownOptionsFromLabelAndValuesArray = (optionsArr) => {
  const optionsToUse = [];
  for (const option of optionsArr) {
    optionsToUse.push({
      name: option.value,
      display_name: option.label,
    });
  }
  return optionsToUse;
};

export const isFormEditedBasedOnCurrentValues = (values, initialValues, fieldsInForm) => {
  let isEdited = false;
  for (const field of fieldsInForm) {
    const backendFieldName = field.backendFieldName;
    const currentValue = getIn(values, backendFieldName);
    const originalValue = getIn(initialValues, backendFieldName);
    if (!isEqual(currentValue, originalValue)) {
      isEdited = true;
      break;
    }
  }
  return isEdited;
};

export const resetFormValuesToInitialValues = (values, fieldsInForm, initialValues, resetForm) => {
  const newValues = {
    ...values,
  };
  for (const field of fieldsInForm) {
    const backendFieldName = field.backendFieldName;
    newValues[backendFieldName] = getIn(initialValues, backendFieldName);
  }
  resetForm({ values: newValues });
};

export const extractRelevantFieldValuesFromForm = (fieldsInForm, values) => {
  const toRet = {};

  for (const field of fieldsInForm) {
    const backendFieldName = field.backendFieldName;
    toRet[backendFieldName] = getIn(values, backendFieldName);

    const transformationFunctionToSendToBackEnd = field.transformationFunctionToSendToBackEnd;
    if (transformationFunctionToSendToBackEnd) {
      toRet[backendFieldName] = transformationFunctionToSendToBackEnd(toRet[backendFieldName]);
    }
  }

  return toRet;
};

export const convertConfigArrayToFrontEndFormat = (configArray) => {
  const newConfigArray = [];

  for (const elem of configArray) {
    newConfigArray.push({
      ...elem,
      isRowFrozen: true,
      isRowOpenedForEditing: false,
      // todo::Fix this and move to SG because other usages dont need this
      rules_count: elem?.rules_count ?? 0,
    });
  }

  return newConfigArray;
};

export const getIfArrayContainDuplicates = (arr) => {
  return new Set(arr).size !== arr.length;
};

export const convertUTCToLocalDate = (date) => {
  if (!date) {
    return date;
  }
  let toRet = date;
  toRet = new Date(toRet);
  toRet = new Date(toRet.getUTCFullYear(), toRet.getUTCMonth(), toRet.getUTCDate());
  return toRet;
};

export const convertLocalToUTCDate = (date) => {
  if (!date) {
    return date;
  }
  let toRet = date;
  toRet = new Date(toRet);
  toRet = new Date(Date.UTC(toRet.getFullYear(), toRet.getMonth(), toRet.getDate()));
  return toRet;
};

const getObjectFromLabelAndValues = (row) => {
  const objectToRet = {};
  for (const [_, value] of Object.entries(row)) {
    objectToRet[value.label] = value.value;
  }
  return objectToRet;
};

const getSummaryRowObjectFromValues = (oldValuesArray) => {
  let isMultiLevelValue = false;

  oldValuesArray.forEach((row) => {
    if (Object.prototype.hasOwnProperty.call(row, 'label')) {
      isMultiLevelValue = true;
    }
  });

  let inputDataSourceFormatted;

  if (!isMultiLevelValue) {
    const toRet = [];
    oldValuesArray.forEach((row) => {
      const thisRowObj = getObjectFromLabelAndValues(row);
      toRet.push(thisRowObj);
    });
    inputDataSourceFormatted = toRet;
  } else {
    const toRet = {};
    oldValuesArray.forEach((row) => {
      const key = row.label;
      toRet[key] = row.values.map((x) => getObjectFromLabelAndValues(x));
    });
    inputDataSourceFormatted = toRet;
  }

  return inputDataSourceFormatted;
};

export const getPlainObjectFromBackEndSpendSummaryFormat = (
  inputDataSource,
  orderingOfRows = []
) => {
  const inputDataSourceFormatted = {};

  for (const value of Object.values(inputDataSource)) {
    const newKey = value.label;
    const oldValues = value.values;
    inputDataSourceFormatted[newKey] = getSummaryRowObjectFromValues(oldValues);
  }

  if (orderingOfRows.length > 0) {
    const inputDataSourceFormattedAndOrdered = {};
    for (const key of orderingOfRows) {
      inputDataSourceFormattedAndOrdered[key] = inputDataSourceFormatted[key];
    }
    return inputDataSourceFormattedAndOrdered;
  }

  return inputDataSourceFormatted;
};

// todo::Implement Input/Output Transformation for all other areas too!

export const getFormattedRegionsUniverse = (dataUniverse, key = 'provider') => {
  const dataUniverseFormatted = [];

  dataUniverse.forEach((providerWithRegions) => {
    const provider = providerWithRegions[key];
    const regions = providerWithRegions.region;
    regions.forEach((regionObj) => {
      dataUniverseFormatted.push({
        provider,
        region: regionObj.region_name,
        region_label: regionObj.region_label,
      });
    });
  });

  return dataUniverseFormatted;
};

export const convertArrayOfValueLabelsToObject = (arrayOfValueLabels) => {
  const toRet = {};

  for (const elem of arrayOfValueLabels) {
    toRet[elem.label] = elem.value;
  }
  return toRet;
};

export const convertObjectToContainKeysInCapitals = (obj) => {
  const objWithKeysInCapitals = {};
  for (const [originalKey, originalValue] of Object.entries(obj)) {
    let valueToUse = originalValue;

    // Recursively convert the value
    if (isPlainObject(originalValue)) {
      valueToUse = convertObjectToContainKeysInCapitals(originalValue);
    }

    objWithKeysInCapitals[originalKey.toUpperCase()] = valueToUse;
  }
  return objWithKeysInCapitals;
};

export const getPercentageValidation = (label, max = 100) =>
  number().label(label).typeError('You must specify a number').min(0).max(max).required();

export const getDeleteWithWarningHeaderAndBody = (objectType, objectName) => {
  const header = `Delete ${objectType} ${objectName}`;
  const headerBody = <DeleteHeaderWithWarning objectType={objectType} />;
  return { header, headerBody };
};
