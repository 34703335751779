// @flow
export const GRID_HEADERS = {
  ORGANIZATION_NAME: 'Organization Name',
  ORGANIZATION_LIST: 'Organization List',
  ORGANIZATION_ID: 'Organization Id',
  LINK: 'link',
  RATE_CARD: 'Rate Card',
};

export const GRID_FIELDNAMES = {
  ORGANIZATION_ID: 'organizationId',
  ORGANIZATION_NAME: 'organizationName',
  ORGANIZATION_LIST: 'organizationList',
  LINK: 'link',
  RATE_CARD: 'rateCard',
};

export const ADD_ORGANIZATION = 'Add Organization';

export const SP_SUMMARY_GRID_HEADERS = {
  NAME: 'SP Name',
  ID: 'SP ID',
  SFDC_ID: 'SP SFDC ID',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  VERSION: 'Version',
};

export const SP_SUMMARY_GRID_FIELDNAMES = {
  NAME: 'name',
  ID: 'id',
  SFDC_ID: 'sp_sfdc_id',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  VERSION: 'version',
};

export const FLINK_POOL_RESOURCES_GRID_HEADERS = {
  ID: 'Flink Pool ID',
  NAME: 'Pool Name',
  PROVIDER: 'Provider',
  REGION: 'Region',
  VERSION: 'Version',
};

export const FLINK_POOL_RESOURCES_GRID_FIELDNAMES = {
  ID: 'id',
  NAME: 'name',
  PROVIDER: 'provider',
  PROVIDER_LABEL: 'provider_label',
  REGION: 'region',
  REGION_LABEL: 'region_label',
  VERSION: 'version',
};

export const CLUSTER_RESOURCES_GRID_HEADERS = {
  ID: 'Cluster ID',
  NAME: 'Cluster Name',
  NETWORKING_TYPE: 'Networking Type',
  TYPE: 'Type',
  AVAILABILITY: 'Availability',
  PROVIDER: 'Provider',
  REGION: 'Region',
  SLA: 'SLA',
  VERSION: 'Version',
};

export const CLUSTER_RESOURCES_GRID_FIELDNAMES = {
  ID: 'id',
  NAME: 'name',
  NETWORKING_TYPE: 'networking_type',
  CLUSTER_TYPE: 'cluster_type',
  AVAILABILITY: 'availability',
  PROVIDER: 'provider',
  REGION: 'region',
  SLA: 'sla',
  VERSION: 'version',
  REGION_LABEL: 'region_label',
  CLUSTER_TYPE_LABEL: 'cluster_type_label',
  AVAILABILITY_LABEL: 'availability_label',
  PROVIDER_LABEL: 'provider_label',
  NETWORKING_TYPE_LABEL: 'networking_type_label',
};
