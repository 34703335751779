// @flow
import React from 'react';
import { useFormikContext } from 'formik';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';

export const SPDateChangeWarningModal = ({ isOpen, setOpen }) => {
  const { initialValues, setFieldValue } = useFormikContext();

  const header = 'Change in duration of Streaming Project';
  const body = (
    <>
      <p>
        The duration of the SP was changed. Please note that if any new additional
        <strong> months are added </strong>, all newly added fields for all components (e.g. Peak
        read/write, avg read/write, connector tasks/throughput etc.)
        <strong> will be set to 0 by default after the changes are saved </strong>. Please ensure
        that these fields are populated correctly in order to get the accurate commit total.
      </p>

      <p>
        If you dont want this behaviour, then, please click <strong>No, revert my changes!</strong>
        to go back to the previous state!
      </p>
    </>
  );

  return (
    <ConfirmModal
      body={body}
      cancelButtonText="No, revert my changes!"
      header={header}
      isOpen={isOpen}
      okButtonText="Yes, I understand and want to proceed!"
      onClickHandlerForCancel={async () => {
        await setFieldValue(
          BACKEND_FIELDNAMES.SP_END_DATE,
          initialValues[BACKEND_FIELDNAMES.SP_END_DATE]
        );
        await setFieldValue(
          BACKEND_FIELDNAMES.SP_START_DATE,
          initialValues[BACKEND_FIELDNAMES.SP_START_DATE]
        );
        setOpen(false);
      }}
      onClickHandlerForOK={() => {
        setOpen(false);
      }}
    />
  );
};
