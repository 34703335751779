// @flow
import { Formik } from 'formik';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { object } from 'yup';
import { Grid } from 'semantic-ui-react';
import { SPSPNameContainer } from '@streaming-projects/sp-page/SPSPNameContainer';
import { SPSPSFDCIDContainer } from '@streaming-projects/sp-page/SPSPSFDCIDContainer';
import { SPStartDateContainer } from '@streaming-projects/sp-page/SPStartDateContainer';
import { SPEndDateContainer } from '@streaming-projects/sp-page/SPEndDateContainer';
import { STREAMING_PROJECTS_SP_DETAILS_CONFIG_MAP } from '@streaming-projects/sp-page/config';
import { useAddStreamingProjectMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { toastError } from '@presentational/notifications/utils';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';
import { getLinkForStreamingProjectsSPPage } from '@streaming-projects/links';

import { Form } from '../../../formik-utils/formikSUIWrapper';
import { StyledContainer, StyledGridRow } from '../../../common-utils/styledComponents';
import { getValidationSchemaFromColsConfig } from '../../../configuration/utils';

export const SPSPAddModal = ({ isOpen, setOpen }) => {
  const [addStreamingProject] = useAddStreamingProjectMutation();
  const { push } = useHistory();
  const { orgId } = useParams();

  const initialValues = {};

  const validationSchema = object({
    ...getValidationSchemaFromColsConfig(STREAMING_PROJECTS_SP_DETAILS_CONFIG_MAP),
  });

  return (
    <StyledContainer data-testid="sp-sp-add-modal">
      <Formik
        autoComplete="off"
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={validationSchema}
      >
        {(addSPSPFormik) => (
          <ConfirmModal
            body={
              <StyledContainer>
                <Form autoComplete="off">
                  <Grid columns={2} divided={true}>
                    <StyledGridRow>
                      <Grid.Column>
                        <SPSPNameContainer disableOnFormErrors={false} />
                        <SPSPSFDCIDContainer disableOnFormErrors={false} />
                      </Grid.Column>

                      <Grid.Column>
                        <SPStartDateContainer
                          disableOnFormErrors={false}
                          showWarningOnChange={false}
                        />
                        <SPEndDateContainer
                          disableOnFormErrors={false}
                          showWarningOnChange={false}
                        />
                      </Grid.Column>
                    </StyledGridRow>
                  </Grid>
                </Form>
              </StyledContainer>
            }
            cancelButtonNegative={true}
            disabled={!addSPSPFormik.dirty || !addSPSPFormik.isValid}
            header="Add New Streaming Project"
            isOpen={isOpen}
            okButtonNegative={false}
            okButtonText="Continue"
            onClickHandlerForCancel={() => {
              addSPSPFormik.resetForm();
              return setOpen(false);
            }}
            onClickHandlerForOK={async () => {
              const { error, data } = await addStreamingProject({
                orgId,
                payload: {
                  org_id: orgId,
                  ...addSPSPFormik.values,
                },
              });

              if (error) {
                toastError(error);
              } else {
                push(getLinkForStreamingProjectsSPPage(orgId, data.sp_id));
              }
              setOpen(false);
            }}
          />
        )}
      </Formik>
    </StyledContainer>
  );
};
