// @flow
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { STREAMING_PROJECTS_API } from '@streaming-projects/constants';

const getInvalidatesTagsFunc = (arrayOfTagsToInvalidate) => (result, error) => {
  // Don't invalidate the cache if a update conflict occurs (HTTP 409).
  // This prevents the latest `version` being fetched via a requery and
  // stops further updates until the browser is refreshed by the user
  if (error?.status === 409) {
    return [];
  }
  return arrayOfTagsToInvalidate;
};

export const streamingProjectsApi = createApi({
  reducerPath: STREAMING_PROJECTS_API,
  baseQuery: fetchBaseQuery({
    baseUrl: `${window?._env_?.API_URL}/api/v2/`,
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken;
      headers.set('Authorization', token ? `Bearer ${token}` : '');
      return headers;
    },
  }),
  tagTypes: ['StreamingProjects'],
  endpoints: (builder) => {
    return {
      // **************** Streaming Projects Related ***************** ///

      getStreamingProjectsHierarchy: builder.query({
        query: ({ query, limit }) => {
          return {
            url: 'views/orghierarchy',
            params: { query, limit },
          };
        },
        providesTags: ['StreamingProjects'],
      }),

      // Add Org
      addOrganization: builder.mutation({
        query: (body) => {
          return {
            url: '/orgs',
            method: 'POST',
            body,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      // Get SP Org
      getSPOrgLevelView: builder.query({
        query: ({ orgId }) => {
          return {
            url: `views/orgs/${orgId}?query=HOME`,
          };
        },
        providesTags: ['StreamingProjects'],
      }),

      updateSPOrg: builder.mutation({
        query: ({ orgId, payload }) => {
          return {
            url: `orgs/${orgId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      // Streaming Projects Related
      deleteStreamingProject: builder.mutation({
        query: ({ orgId, spId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      addStreamingProject: builder.mutation({
        query: ({ orgId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      // Resources Related
      addFlinkPoolResource: builder.mutation({
        query: ({ orgId, payload }) => {
          return {
            url: `orgs/${orgId}/flink-pools`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      updateFlinkPoolResource: builder.mutation({
        query: ({ orgId, flinkPoolResourceId, payload }) => {
          return {
            url: `orgs/${orgId}/flink-pools/${flinkPoolResourceId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      deleteFlinkPoolResource: builder.mutation({
        query: ({ orgId, flinkPoolResourceId, payload }) => {
          return {
            url: `orgs/${orgId}/flink-pools/${flinkPoolResourceId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      getStreamingProjectsMetadata: builder.query({
        query: ({ query }) => {
          return {
            url: 'views/metadata/summary',
            params: { query },
          };
        },
        providesTags: ['StreamingProjects'],
      }),

      addClusterResource: builder.mutation({
        query: ({ orgId, payload }) => {
          return {
            url: `orgs/${orgId}/cluster-resources`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      updateClusterResource: builder.mutation({
        query: ({ orgId, clusterResourceId, payload }) => {
          return {
            url: `orgs/${orgId}/cluster-resources/${clusterResourceId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      deleteClusterResource: builder.mutation({
        query: ({ orgId, clusterResourceId, payload }) => {
          return {
            url: `orgs/${orgId}/cluster-resources/${clusterResourceId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      // End of Config
    };
  },
});

export const {
  // Hierarchy Related
  useGetStreamingProjectsHierarchyQuery,
  // Orgs Related
  useAddOrganizationMutation,
  useGetSPOrgLevelViewQuery,
  useUpdateSPOrgMutation,

  // Streaming Projects Related
  useAddStreamingProjectMutation,
  useDeleteStreamingProjectMutation,

  // Metadata Related
  useGetStreamingProjectsMetadataQuery,

  // Resources Related
  useAddFlinkPoolResourceMutation,
  useUpdateFlinkPoolResourceMutation,
  useDeleteFlinkPoolResourceMutation,
  useAddClusterResourceMutation,
  useUpdateClusterResourceMutation,
  useDeleteClusterResourceMutation,
} = streamingProjectsApi;
