// @flow
import React from 'react';

import { DangerText } from './DangerText';

export const DeleteHeaderWithWarning = ({ objectType }) => {
  return (
    <p>
      <DangerText
        fontSize="1.5rem"
        text={`Are you sure you want to delete this ${objectType}? This action cannot be undone!`}
      />
    </p>
  );
};
