// @flow
import { useParams } from 'react-router-dom';
import React, { useCallback, useMemo, useContext } from 'react';
import { cloneDeep } from 'lodash';

import { DataFetcherContainer } from '../components/presentational/DataFetcherContainer';
import { useGetClusterQuery } from '../service-definitions/estimates';
import { CONNECTOR_AVAILABLE_NETWORKING_TYPES_LIST_BACKEND_NAME } from '../constants';

import { ClusterContext } from './ClusterContext';
import { ConnectorsContext } from './ConnectorsContext';

// todo:: Move this data transformation to the backend - just need to change some key names

export const getFormattedClusterParams = (clusterConfigs) => {
  return clusterConfigs.cluster_params.map((elem) => ({
    ...elem,
    az_configuration: elem.az_config,
  }));
};

export const getConnectorNameToRecordMap = (fullConnectorsListToUse) => {
  const connectorTypes = fullConnectorsListToUse.connector_types;

  for (const elem of connectorTypes) {
    elem[CONNECTOR_AVAILABLE_NETWORKING_TYPES_LIST_BACKEND_NAME] = elem.networking_type;
  }

  const connectorNameToRecordsMap = new Map();
  for (const elem of connectorTypes) {
    const connectorName = elem.connector_name;
    const existingValue = connectorNameToRecordsMap.get(connectorName);
    if (!existingValue) {
      connectorNameToRecordsMap.set(connectorName, []);
    }
    connectorNameToRecordsMap.get(connectorName).push(elem);
  }

  return connectorNameToRecordsMap;
};

export const ClusterContextProvider = ({ children }) => {
  const { estimateId, clusterId } = useParams();
  // todo::Remove this after BE starts sending this data
  const connectorsFullList = useContext(ConnectorsContext);
  const fullConnectorsListToUse = cloneDeep(connectorsFullList);
  const connectorNameToRecordsMap = getConnectorNameToRecordMap(fullConnectorsListToUse);

  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { estimateId: estimateId, clusterId: clusterId },
          {
            skip: !estimateId || !clusterId,
          },
        ],
        [clusterId, estimateId]
      )}
      dataFetchingFunction={useCallback(useGetClusterQuery, [])}
    >
      {(data) => {
        const dataToUse = {
          ...data,
          cluster_configs: {
            cluster_params: getFormattedClusterParams(data.cluster_configs),
            region_details: data.cluster_configs.region_details,
            full_connectors_list: fullConnectorsListToUse,
            connectorNameToRecordsMap: connectorNameToRecordsMap,
            sla_configs: data.cluster_configs.sla_configs,
            cluster_sla_configs: data.cluster_configs.cluster_sla_configs,
          },
        };
        return <ClusterContext.Provider value={dataToUse}>{children}</ClusterContext.Provider>;
      }}
    </DataFetcherContainer>
  );
};
