// @flow
import React from 'react';
import { EditRenderer } from '@components/views/grid-utils/renderers';
import { SPClusterResourceConfigurationAddEditModal } from '@streaming-projects/resource-definitions/cluster-resource-definitions/SPClusterResourceConfigurationAddEditModal';

export const ClusterResourceEditRenderer = ({ data }) => {
  return (
    <EditRenderer>
      {(isEditModalOpen, setEditModalOpen) => {
        return (
          <SPClusterResourceConfigurationAddEditModal
            initialValues={data}
            isEditingMode={true}
            isOpen={isEditModalOpen}
            setOpen={setEditModalOpen}
          />
        );
      }}
    </EditRenderer>
  );
};
