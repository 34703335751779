// @flow
import { Formik } from 'formik';
import React from 'react';
import { object } from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';
import { toastError } from '@presentational/notifications/utils';
import { Spacer } from '@presentational/spacing/Spacer';
import { SPClusterTypeContainer } from '@streaming-projects/resource-definitions/cluster-resource-definitions/SPClusterTypeContainer';
import { SPClusterNameContainer } from '@streaming-projects/resource-definitions/cluster-resource-definitions/SPClusterNameContainer';
import { SPClusterProviderContainer } from '@streaming-projects/resource-definitions/cluster-resource-definitions/SPClusterProviderContainer';
import { SPClusterRegionsContainer } from '@streaming-projects/resource-definitions/cluster-resource-definitions/SPClusterRegionsContainer';
import { SPClusterNetworkingTypeContainer } from '@streaming-projects/resource-definitions/cluster-resource-definitions/SPClusterNetworkingTypeContainer';
import { SPClusterSlaContainer } from '@streaming-projects/resource-definitions/cluster-resource-definitions/SPClusterSLAContainer';
import { SPClusterAvailabilityConfigurationContainer } from '@streaming-projects/resource-definitions/cluster-resource-definitions/SPClusterAvailabilityConfigurationContainer';
import { getLinkForOrganizationsPage } from '@streaming-projects/links';
import {
  useAddClusterResourceMutation,
  useUpdateClusterResourceMutation,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import { CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG } from '@streaming-projects/resource-definitions/cluster-resource-definitions/cluster-resource-inputs-config';
import {
  CLUSTER_RESOURCES_GRID_FIELDNAMES,
  CLUSTER_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { ResourceEditRepriceWarningContainer } from '@streaming-projects/resource-definitions/auxilary-components/ResourceEditRepriceWarningContainer';

import { getValidationSchemaFromColsConfig } from '../../../../configuration/utils';
import {
  CLUSTER_AVAILABILITY_BACKEND_NAME,
  CLUSTER_NAME_BACKEND_NAME,
  CLUSTER_NETWORKING_TYPE_BACKEND_NAME,
  CLUSTER_PROVIDER_BACKEND_NAME,
  CLUSTER_REGION_BACKEND_NAME,
  CLUSTER_SLA_BACKEND_NAME,
  CLUSTER_TYPE_BACKEND_NAME,
} from '../../../../constants';
import { StyledContainer } from '../../../../common-utils/styledComponents';

const getAddAndUpdateParams = (orgId, initialValues, addClusterFormik) => {
  // Note, clusterResourceId and Version are only applicable in Editing Mode and hence the usage of "?."
  return {
    orgId,
    clusterResourceId: initialValues?.[CLUSTER_RESOURCES_GRID_HEADERS.ID],
    payload: {
      org_id: orgId,
      name: addClusterFormik.values[CLUSTER_RESOURCES_GRID_FIELDNAMES.NAME],
      inputs: {
        ...addClusterFormik.values,
      },
      version: initialValues?.[CLUSTER_RESOURCES_GRID_HEADERS.VERSION],
    },
  };
};

export const SPClusterResourceConfigurationAddEditModal = ({
  isOpen,
  setOpen,
  isEditingMode,
  initialValues = null,
}) => {
  const { orgId } = useParams();
  const [addClusterResource] = useAddClusterResourceMutation();
  const [updateClusterResource] = useUpdateClusterResourceMutation();
  const { push } = useHistory();

  const initialValuesToUse = isEditingMode
    ? {
        [CLUSTER_NAME_BACKEND_NAME]: initialValues[CLUSTER_RESOURCES_GRID_HEADERS.NAME],
        [CLUSTER_TYPE_BACKEND_NAME]: initialValues[CLUSTER_RESOURCES_GRID_HEADERS.TYPE],
        [CLUSTER_NETWORKING_TYPE_BACKEND_NAME]:
          initialValues[CLUSTER_RESOURCES_GRID_HEADERS.NETWORKING_TYPE],
        [CLUSTER_SLA_BACKEND_NAME]: initialValues[CLUSTER_RESOURCES_GRID_HEADERS.SLA],
        [CLUSTER_AVAILABILITY_BACKEND_NAME]:
          initialValues[CLUSTER_RESOURCES_GRID_HEADERS.AVAILABILITY],
        [CLUSTER_PROVIDER_BACKEND_NAME]: initialValues[CLUSTER_RESOURCES_GRID_HEADERS.PROVIDER],
        [CLUSTER_REGION_BACKEND_NAME]: initialValues[CLUSTER_RESOURCES_GRID_HEADERS.REGION],
      }
    : {
        // todo::SP don't show errors when the Add Modal is opened
        [CLUSTER_NAME_BACKEND_NAME]: null,
        [CLUSTER_TYPE_BACKEND_NAME]: null,
        [CLUSTER_NETWORKING_TYPE_BACKEND_NAME]: null,
        [CLUSTER_SLA_BACKEND_NAME]: null,
        [CLUSTER_AVAILABILITY_BACKEND_NAME]: null,
        [CLUSTER_PROVIDER_BACKEND_NAME]: null,
        [CLUSTER_REGION_BACKEND_NAME]: null,
      };

  const validationSchema = object({
    ...getValidationSchemaFromColsConfig(CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG),
  });

  return (
    <StyledContainer>
      <Formik
        autoComplete="off"
        enableReinitialize={true}
        initialValues={initialValuesToUse}
        onSubmit={() => {}}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {(addClusterFormik) => {
          return (
            <ConfirmModal
              body={
                <>
                  <Grid columns={3}>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <SPClusterNameContainer disableOnFormErrors={false} fluid={true} />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={4}>
                        <SPClusterTypeContainer disableOnFormErrors={false} fluid={true} />
                      </Grid.Column>
                      <Grid.Column width={6}>
                        <SPClusterProviderContainer disableOnFormErrors={false} fluid={true} />
                      </Grid.Column>
                      <Grid.Column width={6}>
                        <SPClusterRegionsContainer disableOnFormErrors={false} fluid={true} />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={4}>
                        <SPClusterAvailabilityConfigurationContainer
                          disableOnFormErrors={false}
                          fluid={true}
                        />
                      </Grid.Column>
                      <Grid.Column width={6}>
                        <SPClusterSlaContainer disableOnFormErrors={false} fluid={true} />
                      </Grid.Column>
                      <Grid.Column width={6}>
                        <SPClusterNetworkingTypeContainer
                          disableOnFormErrors={false}
                          fluid={true}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Spacer y={20} />
                  {isEditingMode && <ResourceEditRepriceWarningContainer resourceType="Cluster" />}
                </>
              }
              cancelButtonNegative={true}
              disabled={!addClusterFormik.isValid}
              header={
                isEditingMode
                  ? 'Edit Cluster Resource Configuration'
                  : 'Add New Cluster Resource Configuration'
              }
              isOpen={isOpen}
              okButtonNegative={false}
              okButtonText="Continue"
              onClickHandlerForCancel={() => {
                addClusterFormik.resetForm();
                return setOpen(false);
              }}
              onClickHandlerForOK={async () => {
                let errorInCreateOrUpdate;
                if (!isEditingMode) {
                  const { error } = await addClusterResource(
                    getAddAndUpdateParams(orgId, initialValues, addClusterFormik)
                  );
                  errorInCreateOrUpdate = error;
                } else {
                  const { error } = await updateClusterResource(
                    getAddAndUpdateParams(orgId, initialValues, addClusterFormik)
                  );
                  errorInCreateOrUpdate = error;
                }
                if (errorInCreateOrUpdate) {
                  toastError(errorInCreateOrUpdate);
                } else {
                  push(getLinkForOrganizationsPage(orgId));
                }
                setOpen(false);
              }}
            />
          );
        }}
      </Formik>
    </StyledContainer>
  );
};
