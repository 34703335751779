// @flow
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { StreamingProjectsHome } from '@streaming-projects/home-page/StreamingProjectsHome';
import {
  getLinkForOrganizationsPage,
  getLinkForStreamingProjectsHomePage,
} from '@streaming-projects/links';
import { FlinkPoolTopLevelContainer } from '@components/flink-pool-top-level/FlinkPoolTopLevelContainer';
import { SPOrgTopLevelContainer } from '@streaming-projects/orgs/org-top-level/SPOrgTopLevelContainer';

import {
  getLinkForCluster,
  getLinkForEstimate,
  getLinkForFlinkPool,
  getLinkForHomePage,
  getLinkForStreamGovernance,
} from './components/links/utils';
import { StreamGovernanceTopLevelContainer } from './components/stream-governance/StreamGovernanceTopLevelContainer';
import EstimateTopLevelContainer from './components/estimate-top-level/EstimateTopLevelContainer';
import ClusterTopLevelContainer from './components/cluster/cluster-top-level/ClusterTopLevelContainer';
import { TopBar } from './components/main-header/TopBar';
import { getThemeDetails, GlobalStyle } from './theming/themes';
import { CookieContext } from './contexts/CookieContext';
import {
  DEFAULT_BACKGROUND_COLOR_FOR_DARK_THEME,
  DEFAULT_BACKGROUND_COLOR_FOR_LIGHT_THEME,
  THEME_LIGHT,
} from './constants';
import { Spacer } from './components/presentational/spacing/Spacer';
import { oktaAuthConfig } from './authentication/OKTAConfig';
import AuthLogout from './authentication/AuthLogout';
import { NotFoundPage } from './components/NotFoundPage';
import { LoadingIndicator } from './components/presentational/LoadingIndicator';

const oktaAuth = new OktaAuth(oktaAuthConfig);

const Home = React.lazy(() => import(/* webpackChunkName: "Home" */ './components/home-page/Home'));

const AppRoutes = () => {
  const { cookies } = useContext(CookieContext);
  const theme = cookies?.theme ?? THEME_LIGHT;

  return (
    <ThemeProvider theme={getThemeDetails(theme)}>
      <GlobalStyle
        backgroundColor={
          theme === THEME_LIGHT
            ? DEFAULT_BACKGROUND_COLOR_FOR_LIGHT_THEME
            : DEFAULT_BACKGROUND_COLOR_FOR_DARK_THEME
        }
      />
      <TopBar />

      <React.Suspense fallback={<LoadingIndicator />}>
        <Switch>
          <SecureRoute exact={true} path={getLinkForHomePage()}>
            <Home />
          </SecureRoute>

          <SecureRoute exact={true} path={getLinkForEstimate(':estimateId')}>
            <EstimateTopLevelContainer />
          </SecureRoute>

          <SecureRoute exact={true} path={getLinkForCluster(':estimateId', ':clusterId')}>
            <ClusterTopLevelContainer />
          </SecureRoute>

          <SecureRoute exact={true} path={getLinkForCluster(':estimateId')}>
            <ClusterTopLevelContainer />
          </SecureRoute>

          <SecureRoute exact={true} path={getLinkForStreamGovernance(':estimateId')}>
            <StreamGovernanceTopLevelContainer />
          </SecureRoute>

          <SecureRoute exact={true} path={getLinkForFlinkPool(':estimateId', ':flinkPoolId')}>
            <FlinkPoolTopLevelContainer />
          </SecureRoute>

          <Route component={LoginCallback} path="/login/oauth2/code/okta" />
          <Route
            path="/login"
            render={() => {
              oktaAuth.signInWithRedirect();
            }}
          />
          <Route path="/logout" render={() => <AuthLogout />} />

          {/* Streaming Projects Links */}
          <SecureRoute exact={true} path={getLinkForStreamingProjectsHomePage()}>
            <StreamingProjectsHome />
          </SecureRoute>

          <SecureRoute exact={true} path={getLinkForOrganizationsPage(':orgId')}>
            <SPOrgTopLevelContainer />
          </SecureRoute>

          <Route component={NotFoundPage} />
        </Switch>
      </React.Suspense>
      <Spacer y={100} />
      {/* Spacer is required to give some additional white space at the bottom for aesthetic reasons*/}
    </ThemeProvider>
  );
};

export default AppRoutes;
