// @flow
import React from 'react';

import { StyledMenuItem } from '../../common-utils/styledComponents';
import { getLinkForHomePage } from '../links/utils';
import { Spacer } from '../presentational/spacing/Spacer';
import { EstimatesViewIcon } from '../presentational/icons/EstimatesViewIcon';
import { ALinkWithInheritedColorAndUnderline } from '../presentational/ALinkWithInheritedColorAndUnderline';

export const EstimatesViewLink = () => {
  return (
    <StyledMenuItem>
      <ALinkWithInheritedColorAndUnderline href={getLinkForHomePage()}>
        <EstimatesViewIcon />
        <Spacer x={5} />
        Go To Estimates View
      </ALinkWithInheritedColorAndUnderline>
    </StyledMenuItem>
  );
};
