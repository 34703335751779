// @flow
import React from 'react';

const MappedDataRenderer = ({ value, mapping }) => {
  let renderValue = value;
  if (mapping[value]) {
    renderValue = mapping[value];
  }
  return <>{renderValue}</>;
};

export default MappedDataRenderer;
