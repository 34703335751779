// @flow
import React, { useState } from 'react';
import { Divider, Grid } from 'semantic-ui-react';
import { Spacer } from '@presentational/spacing/Spacer';
import { DEFAULT_NUMBER_OF_ITEMS_TO_SHOW_ON_HOME_PAGE } from '@streaming-projects/constants';
import { ShowLoaderIfAnyQueryIsPending } from '@presentational/ShowLoaderIfAnyQueryIsPending';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { StyledH3 } from '@presentational/headings/StyledH3';
import { UserContextProvider } from '@src/contexts/UserContextProvider';
import { AddNewButton } from '@presentational/buttons/AddNewButton';
import { ADD_ORGANIZATION } from '@streaming-projects/orgs/enums';
import { SearchForm } from '@presentational/SearchForm';

import { SPHierarchyContainer } from './hierarchy-view/SPHierarchyContainer';
import { SPOrgAddModal } from './auxilary-components/SPOrgAddModal';

export const StreamingProjectsHome = () => {
  const [limit, setLimit] = useState(DEFAULT_NUMBER_OF_ITEMS_TO_SHOW_ON_HOME_PAGE);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSPOrgAddModalOpen, setSPOrgAddModalOpen] = useState(false);
  return (
    <>
      <ShowLoaderIfAnyQueryIsPending />
      <UserContextProvider>
        <StyledContainer>
          <StyledH3 text="My Accounts" />
          <Divider />

          <Grid>
            <Grid.Column floated="left" width={11}>
              <SearchForm
                onClickHandler={(val) => {
                  setSearchTerm(val);
                }}
                searchTerm={searchTerm}
              />
            </Grid.Column>
            <Grid.Column floated="right" width={5}>
              <AddNewButton onClick={() => setSPOrgAddModalOpen(true)} text={ADD_ORGANIZATION} />
            </Grid.Column>
          </Grid>

          <SPOrgAddModal isOpen={isSPOrgAddModalOpen} onClose={() => setSPOrgAddModalOpen(false)} />

          <Divider />
          <Spacer y={3} />
          <SPHierarchyContainer
            limit={limit}
            onLimitChange={(newLimit) => setLimit(newLimit)}
            searchTerm={searchTerm}
          />
        </StyledContainer>
      </UserContextProvider>
    </>
  );
};
