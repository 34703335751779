// @flow
import React from 'react';
import { useParams } from 'react-router-dom';
import { getLinkForStreamingProjectsSPPage } from '@streaming-projects/links';
import { LinkRenderer } from '@components/views/grid-utils/renderers';

export const SPNameRenderer = ({ data: { id, name } }) => {
  const { orgId } = useParams();
  return <LinkRenderer pathName={getLinkForStreamingProjectsSPPage(orgId, id)} text={name} />;
};
