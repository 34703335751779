// @flow

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { DISABLED, ENABLED } from '../../../constants';
import { DeleteIcon } from '../../presentational/icons/DeleteIcon';
import { CopyIcon } from '../../presentational/icons/CopyIcon';
import { convertUTCTimeStampToHumanReadableTime } from '../../../common-utils/utils';
import { ShareIcon } from '../../presentational/icons/ShareIcon';
import { EditIcon } from '../../presentational/icons/EditIcon';

export const LinkRendererForMonthlySummaryView = ({ data }) => {
  return data.link ? (
    <LinkRenderer pathName={data.link} text={data.Field} />
  ) : (
    <span> {data.Field} </span>
  );
};

export const LinkRenderer = ({ pathName, text }) => {
  // If the Link is used outside formik context, then assume isValid as true otherwise take it from formik
  let isValid = true;

  const formikContext = useFormikContext();
  if (formikContext) {
    isValid = formikContext.isValid;
  }

  const isLinkInvalid = !isValid;

  // todo:: isLinkInvalid -> isClickable or not
  // todo:: LinkRenderer should take in a prop called isLinkInvalid/isClickable; Similarly others in this file

  return (
    <Link
      disabled={isLinkInvalid}
      style={isLinkInvalid ? { pointerEvents: 'none' } : {}}
      to={{
        pathname: pathName,
      }}
    >
      {text}
    </Link>
  );
};

export const CopyRenderer = ({ onClickHandler }) => {
  // If the Link is used outside formik context, then assume isValid as true otherwise take it from formik
  let isValid = true;

  const formikContext = useFormikContext();
  if (formikContext) {
    isValid = formikContext.isValid;
  }

  return (
    <>
      <CopyIcon disabled={!isValid} onClick={onClickHandler} />
    </>
  );
};

export const DeleteRenderer = ({ children }) => {
  // If the Link is used outside formik context, then assume isValid as true otherwise take it from formik
  let isValid = true;

  const formikContext = useFormikContext();
  if (formikContext) {
    isValid = formikContext.isValid;
  }

  // todo:: Remove state setter
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <>
      <DeleteIcon
        disabled={!isValid}
        onClick={() => {
          setDeleteModalOpen(true);
        }}
      />
      {children(isDeleteModalOpen, setDeleteModalOpen)}
    </>
  );
};

export const EditRenderer = ({ children }) => {
  // If the Link is used outside formik context, then assume isValid as true otherwise take it from formik
  let isValid = true;

  const formikContext = useFormikContext();
  if (formikContext) {
    isValid = formikContext.isValid;
  }

  // todo:: Remove state setter
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  return (
    <>
      <EditIcon
        disabled={!isValid}
        onClick={() => {
          setEditModalOpen(true);
        }}
      />
      {children(isEditModalOpen, setEditModalOpen)}
    </>
  );
};

export const ShareRenderer = ({ children }) => {
  // If the Link is used outside formik context, then assume isValid as true otherwise take it from formik
  let isValid = true;

  const formikContext = useFormikContext();
  if (formikContext) {
    isValid = formikContext.isValid;
  }

  const [isShareModalOpen, setShareModalOpen] = useState(false);

  return (
    <>
      <ShareIcon
        disabled={!isValid}
        onClick={() => {
          setShareModalOpen(true);
        }}
      />
      {children(isShareModalOpen, setShareModalOpen)}
    </>
  );
};

const formatCurrency0DP = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const formatCurrency4DP = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 4,
  maximumFractionDigits: 4,
});

export const formatFloat0DP = (floatNum) => {
  if (typeof floatNum === 'string') {
    return floatNum;
  }
  return parseFloat(floatNum.toFixed(0));
};

export const formatFloat1DP = (floatNum) => {
  if (typeof floatNum === 'string') {
    return floatNum;
  }
  return parseFloat(floatNum.toFixed(1));
};

export const formatFloat2DP = (floatNum) => {
  if (typeof floatNum === 'string') {
    return floatNum;
  }
  return parseFloat(floatNum.toFixed(2));
};

export const formatFloat4DP = (floatNum) => {
  if (typeof floatNum === 'string') {
    return floatNum;
  }
  return parseFloat(floatNum.toFixed(4));
};

export const MarginRenderer = (props) => {
  if (props.value == null) {
    return null;
  }

  if (props.value === 'DIV/0') {
    return <span>{props.value}</span>;
  }

  return <span>{`${formatFloat1DP(props.value)}%`}</span>;
};

export const PercentageRenderer = (props) =>
  props.value != null ? <span>{`${props.value}%`}</span> : null;

export const CurrencyZeroDPRenderer = (props) =>
  props.value != null ? <span>{formatCurrency0DP.format(props.value)}</span> : null;

export const CurrencyFourDPRenderer = (props) =>
  props.value != null ? <span>{formatCurrency4DP.format(props.value)}</span> : null;

export const EnabledCellRenderer = (props) =>
  props.value != null ? <span>{props.value ? ENABLED : DISABLED}</span> : null;

const ChildRendererInternal = (props, addHyphen = true) => {
  const hyphenString = addHyphen ? '- ' : '';

  return props.value != null && props.data.is_child ? (
    <span
      style={{
        marginLeft: '12px',
      }}
    >
      {`${hyphenString}${props.value}`}
    </span>
  ) : (
    <span> {props.value} </span>
  );
};

export const ChildRenderer = (props) => {
  return ChildRendererInternal(props);
};

export const ChildRendererWithoutHyphen = (props) => {
  return ChildRendererInternal(props, false);
};

export const DepthAwareChildRenderer = (props) => {
  return DepthAwareChildRendererInternal(props);
};

const DepthAwareChildRendererInternal = (props, addHyphen = true) => {
  const value = props.value;
  const depth = props.data.depth;
  const marginLeft = depth * 20;
  const hyphenString = addHyphen && depth > 0 ? '- ' : '';

  return (
    value != null && (
      <span
        style={{
          marginLeft: `${marginLeft}px`,
        }}
      >
        {`${hyphenString}${value}`}
      </span>
    )
  );
};

export const Number2DPRenderer = (props) => {
  return props.value != null ? <span>{formatFloat2DP(Number(props.value))}</span> : null;
};

export const Number4DPRenderer = (props) => {
  return props.value != null ? <span>{formatFloat4DP(Number(props.value))}</span> : null;
};

export const NumberRoundedRenderer = (props) => {
  return props.value != null ? <span>{Math.round(props.value)}</span> : null;
};

export const TimeStampRenderer = (props) => {
  return props.value != null ? (
    <span>{convertUTCTimeStampToHumanReadableTime(props.value)}</span>
  ) : null;
};

export const StringOrIntegerWithoutDecimalsRenderer = (props) => {
  if (props.value == null) {
    return null;
  }

  let valueToUse = props.value;

  const numberFromString = Number(props.value);
  if (Number.isInteger(numberFromString)) {
    valueToUse = formatFloat0DP(numberFromString);
  }

  return <span>{valueToUse}</span>;
};
