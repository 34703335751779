// @flow
import React, { useCallback, useContext, useMemo } from 'react';
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import { useGetStreamingProjectsMetadataQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { getProviderRegionsMapFromMetaData } from '@streaming-projects/utils';
import { getFormattedClusterParams } from '@src/contexts/ClusterContextProvider';

import { SPMetaDataContext } from './SPMetaDataContext';
import { SPOrgContext } from './SPOrgContext';

export const getFormattedClusterConfigs = (clusterConfigs) => {
  const embellishedClusterParams = getFormattedClusterParams(clusterConfigs);

  return {
    ...clusterConfigs,
    cluster_params: embellishedClusterParams,
  };
};

export const SPMetaDataContextProvider = ({ children }) => {
  const spOrgData = useContext(SPOrgContext);
  const {
    org: { rate_card: rateCard },
  } = spOrgData;

  if (!rateCard) {
    throw new Error('An organisation must have a valid rate card');
  }

  const args = useMemo(
    () => [
      {
        query: `RC_META,REGION_DETAILS,FLINK_POOL_CONFIGS,CLUSTER_CONFIGS,CLUSTER_CONFIG_NAME_LABEL,RATE_CARD=${rateCard}`,
      },
    ],
    [rateCard]
  );

  return (
    <DataFetcherContainer
      dataFetchingArgs={args}
      dataFetchingFunction={useCallback(useGetStreamingProjectsMetadataQuery, [])}
    >
      {(data) => {
        const { providerRegionsMap, providerNameLabelMap } =
          getProviderRegionsMapFromMetaData(data);

        // todo::SP discuss with BE to update the below api contract
        const resourceConfigurationsNameLabelsMap = data.cluster_config_name_labels;
        // todo::SP Ask BE to send az_configuration and remove getFormattedClusterParams special handling
        return (
          <SPMetaDataContext.Provider
            value={{
              metaData: {
                ...data,
                providerNameLabelMap,
                providerRegionsMap,
                resourceConfigurationsNameLabelsMap,
                clusterResourceInputConfigs: getFormattedClusterConfigs(data.cluster_configs),
              },
            }}
          >
            {children}
          </SPMetaDataContext.Provider>
        );
      }}
    </DataFetcherContainer>
  );
};
