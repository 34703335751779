// @flow
import { string } from 'yup';
import { DATE_INPUT_TYPE, TEXT_INPUT_TYPE } from '@src/formik-utils/consts';
import { BACKEND_FIELDNAMES, FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';
import { convertLocalToUTCDate, convertUTCToLocalDate } from '@src/common-utils/utils';
import { convertConfigArrayToMap } from '@src/configuration/utils';

export const STREAMING_PROJECTS_SP_DETAILS_CONFIG = [
  {
    displayName: FRONT_END_DISPLAY_NAMES.SP_NAME,
    backendFieldName: BACKEND_FIELDNAMES.SP_NAME,
    inputType: TEXT_INPUT_TYPE,
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.SP_ID,
    backendFieldName: BACKEND_FIELDNAMES.SP_SFDC_ID,
    inputType: TEXT_INPUT_TYPE,
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.SP_START_DATE,
    backendFieldName: BACKEND_FIELDNAMES.SP_START_DATE,
    validation: string().label(FRONT_END_DISPLAY_NAMES.SP_START_DATE).required(),
    inputType: DATE_INPUT_TYPE,
    inputTransformationFunc: convertUTCToLocalDate,
    transformationFunctionToSendToBackEnd: convertLocalToUTCDate,
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.SP_END_DATE,
    backendFieldName: BACKEND_FIELDNAMES.SP_END_DATE,
    validation: string().label(FRONT_END_DISPLAY_NAMES.SP_END_DATE).required(),
    inputType: DATE_INPUT_TYPE,
    inputTransformationFunc: convertUTCToLocalDate,
    transformationFunctionToSendToBackEnd: convertLocalToUTCDate,
  },
];

export const STREAMING_PROJECTS_SP_DETAILS_CONFIG_MAP = convertConfigArrayToMap(
  STREAMING_PROJECTS_SP_DETAILS_CONFIG
);
