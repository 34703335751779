// @flow
import { DataGrid } from '@components/views/grid-utils/DataGrid';
import { Spacer } from '@presentational/spacing/Spacer';
import React, { useState } from 'react';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { AddNewButton } from '@presentational/buttons/AddNewButton';
import { ADD_ORGANIZATION, GRID_FIELDNAMES, GRID_HEADERS } from '@streaming-projects/orgs/enums';
import { getOrgDefaultValues } from '@streaming-projects/utils';

import { SPOrgAddModal } from '../auxilary-components/SPOrgAddModal';

import { OrganizationNameRenderer } from './auxilary-components/OrganizationNameRenderer';

export const SPOrganizationsGrid = ({ context, parentData }) => {
  const [isOrgAddModalOpen, setOrgAddModalOpen] = useState(false);
  const inputDataSource = Object.values(parentData.Account.children);

  const { highlightTerm } = context;
  const { Account } = parentData;

  const ORG_COLUMN_DEFS = [
    {
      field: GRID_FIELDNAMES.ORGANIZATION_NAME,
      backEndFieldName: BACKEND_FIELDNAMES.NAME,
      headerName: GRID_HEADERS.ORGANIZATION_NAME,
      width: 300,
      cellRenderer: ({ data: { link, organizationName } }) => (
        <OrganizationNameRenderer
          highlightTerm={highlightTerm}
          link={link}
          organizationName={organizationName}
        />
      ),
    },
    {
      field: GRID_FIELDNAMES.ORGANIZATION_ID,
      backEndFieldName: BACKEND_FIELDNAMES.ID,
      headerName: GRID_HEADERS.ORGANIZATION_ID,
    },
    {
      field: GRID_FIELDNAMES.LINK,
      backEndFieldName: BACKEND_FIELDNAMES.LINK,
      headerName: GRID_HEADERS.LINK,
      hide: true,
    },
    {
      field: GRID_FIELDNAMES.RATE_CARD,
      backEndFieldName: BACKEND_FIELDNAMES.RATE_CARD,
      headerName: GRID_HEADERS.RATE_CARD,
    },
  ];

  return (
    <>
      <DataGrid
        columnDefs={ORG_COLUMN_DEFS}
        inputDataSource={inputDataSource}
        label={GRID_HEADERS.ORGANIZATION_LIST}
        sizeColumnsToFitInitially={true}
      />
      <Spacer y={5} />
      <AddNewButton
        onClick={(e) => {
          e.stopPropagation();
          setOrgAddModalOpen(true);
        }}
        text={ADD_ORGANIZATION}
      />

      <SPOrgAddModal
        defaultValues={getOrgDefaultValues(Account)}
        isOpen={isOrgAddModalOpen}
        onClose={() => setOrgAddModalOpen(false)}
      />
    </>
  );
};
