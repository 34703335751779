// @flow
import {} from '@streaming-projects/orgs/enums';
import { string } from 'yup';
import { convertConfigArrayToMap } from '@src/configuration/utils';
import {
  CLUSTER_AVAILABILITY,
  CLUSTER_AVAILABILITY_BACKEND_NAME,
  CLUSTER_AVAILABILITY_LABEL_BACKEND_NAME,
  CLUSTER_DETAILS_FORM,
  CLUSTER_NAME,
  CLUSTER_NAME_BACKEND_NAME,
  CLUSTER_NETWORKING_TYPE,
  CLUSTER_NETWORKING_TYPE_BACKEND_NAME,
  CLUSTER_NETWORKING_TYPE_LABEL_BACKEND_NAME,
  CLUSTER_PROVIDER,
  CLUSTER_PROVIDER_BACKEND_NAME,
  CLUSTER_PROVIDER_LABEL_BACKEND_NAME,
  CLUSTER_REGION,
  CLUSTER_REGION_BACKEND_NAME,
  CLUSTER_REGION_LABEL_BACKEND_NAME,
  CLUSTER_SLA_BACKEND_NAME,
  CLUSTER_SLA_DISPLAY_COL_MAPPING,
  CLUSTER_SLA_DISPLAY_NAME,
  CLUSTER_TYPE,
  CLUSTER_TYPE_BACKEND_NAME,
  CLUSTER_TYPE_LABEL_BACKEND_NAME,
} from '@src/constants';
import { SELECT_INPUT_TYPE, TEXT_INPUT_TYPE } from '@src/formik-utils/consts';

export const CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG = [
  {
    displayName: CLUSTER_NAME,
    backendFieldName: CLUSTER_NAME_BACKEND_NAME,
    icon: null,
    validation: string().label(CLUSTER_NAME).required(),
    inputType: TEXT_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
  },
  {
    displayName: CLUSTER_TYPE,
    backendFieldName: CLUSTER_TYPE_BACKEND_NAME,
    displayNameCol: CLUSTER_TYPE_LABEL_BACKEND_NAME,
    icon: null,
    inputType: SELECT_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
    validation: string().required().label(CLUSTER_TYPE).nullable(),
    dependentFields: [
      CLUSTER_PROVIDER_BACKEND_NAME,
      CLUSTER_AVAILABILITY_BACKEND_NAME,
      CLUSTER_NETWORKING_TYPE_BACKEND_NAME,
    ],
  },
  {
    displayName: CLUSTER_PROVIDER,
    backendFieldName: CLUSTER_PROVIDER_BACKEND_NAME,
    displayNameCol: CLUSTER_PROVIDER_LABEL_BACKEND_NAME,
    icon: null,
    inputType: SELECT_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
    validation: string().required().label(CLUSTER_PROVIDER).nullable(),
    parentFieldsInGroup: [CLUSTER_TYPE_BACKEND_NAME],
    dependentFields: [CLUSTER_AVAILABILITY_BACKEND_NAME, CLUSTER_NETWORKING_TYPE_BACKEND_NAME],
  },
  {
    displayName: CLUSTER_REGION,
    backendFieldName: CLUSTER_REGION_BACKEND_NAME,
    icon: null,
    inputType: SELECT_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
    validation: string().required().label(CLUSTER_REGION).nullable(),
    parentFieldsInGroup: [CLUSTER_PROVIDER_BACKEND_NAME],
    displayNameCol: CLUSTER_REGION_LABEL_BACKEND_NAME,
  },
  {
    displayName: CLUSTER_AVAILABILITY,
    backendFieldName: CLUSTER_AVAILABILITY_BACKEND_NAME,
    displayNameCol: CLUSTER_AVAILABILITY_LABEL_BACKEND_NAME,
    icon: null,
    inputType: SELECT_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
    validation: string().required().label(CLUSTER_AVAILABILITY).nullable(),
    parentFieldsInGroup: [CLUSTER_TYPE_BACKEND_NAME, CLUSTER_PROVIDER_BACKEND_NAME],
    dependentFields: [CLUSTER_NETWORKING_TYPE_BACKEND_NAME],
  },
  {
    displayName: CLUSTER_NETWORKING_TYPE,
    backendFieldName: CLUSTER_NETWORKING_TYPE_BACKEND_NAME,
    icon: null,
    inputType: SELECT_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
    displayNameCol: CLUSTER_NETWORKING_TYPE_LABEL_BACKEND_NAME,
    validation: string().required().label(CLUSTER_NETWORKING_TYPE).nullable(),
    parentFieldsInGroup: [
      CLUSTER_TYPE_BACKEND_NAME,
      CLUSTER_PROVIDER_BACKEND_NAME,
      CLUSTER_AVAILABILITY_BACKEND_NAME,
    ],
  },
  {
    displayName: CLUSTER_SLA_DISPLAY_NAME,
    backendFieldName: CLUSTER_SLA_BACKEND_NAME,
    icon: null,
    inputType: SELECT_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
    displayNameCol: CLUSTER_SLA_DISPLAY_COL_MAPPING,
    parentFieldsInGroup: [CLUSTER_TYPE_BACKEND_NAME, CLUSTER_AVAILABILITY_BACKEND_NAME],
    validation: string().required().label(CLUSTER_SLA_DISPLAY_NAME).nullable(),
  },
];

export const CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG_MAP = convertConfigArrayToMap(
  CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG
);

// todo::SP Change these constants to use new SP ones
