// @flow

import React from 'react';

import { StyledButton } from '../../../common-utils/styledComponents';

export const GoButton = ({ disabled, onClick, size = 'medium' }) => (
  <StyledButton
    circular={true}
    compact={true}
    data-testid="go-button"
    disabled={disabled}
    icon="angle double right"
    onClick={onClick}
    positive={true}
    size={size}
  />
);
