// @flow
import { useEffect } from 'react';

import {
  AWS,
  CLUSTER_FOLLOWER_FETCH_BACKEND_NAME,
  CLUSTER_NETWORKING_TYPE_BACKEND_NAME,
  CLUSTER_PROVIDER_BACKEND_NAME,
  CLUSTER_TYPE_BACKEND_NAME,
  PEERED_VPC,
  DEDICATED_CLUSTER_TYPE,
  CLUSTER_DETAILS_FORM,
  CLUSTER_KAFKA_FORM,
  CLUSTER_KSQLDB_FORM,
  CLUSTER_CONNECTORS_FORM,
  CLUSTER_CLUSTER_LINKING_FORM,
  FLINK_POOL_DETAILS_FORM,
  FLINK_POOL_MONTHLY_INPUTS_FORM,
  ENTERPRISE_CLUSTER_TYPE,
} from '../../../constants';
import { getDropdownOptions } from '../../stream-governance/utils';
import {
  CLUSTER_DETAILS_CONFIG_MAP,
  CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP,
} from '../../../configuration/cluster-details';
import {
  extractRelevantFieldValuesFromForm,
  getIfCustomerViewIsEnabled,
  isFormEditedBasedOnCurrentValues,
  resetFormValuesToInitialValues,
} from '../../../common-utils/utils';
import { FLINK_POOL_DETAILS_FORM_NAMES_TO_FIELDS_MAP } from '../../../configuration/flink-pool-details';

export const handleDependedClusterInputFields = (
  columnConfig,
  dataUniverse,
  values,
  setFieldValue,
  setFieldTouched
) => {
  const dependentFieldsArr = columnConfig?.dependentFields ?? [];
  for (const dep of dependentFieldsArr) {
    const options = getDropdownOptions(dataUniverse, values, CLUSTER_DETAILS_CONFIG_MAP.get(dep));

    if (!options.map((x) => x.name).includes(values[dep])) {
      setFieldValue(dep, null);
      setFieldTouched(dep, true);
    }
    if (options.length === 1) {
      setFieldValue(dep, options[0].name);
    }
  }
};

export const getRelevantValues = (columnConfig, values) => {
  const valuesToUse = {};
  const parentFieldsInGroup = columnConfig?.parentFieldsInGroup ?? [];
  const dependentFieldsArr = columnConfig?.dependentFields ?? [];

  for (const field of parentFieldsInGroup) {
    valuesToUse[field] = values[field];
  }

  for (const field of dependentFieldsArr) {
    valuesToUse[field] = values[field];
  }
  return valuesToUse;
};

export const useClusterInputsDependencies = (
  columnConfig,
  dataUniverse,
  values,
  setFieldValue,
  setFieldTouched
) => {
  // todo:: This is causing slowness as the Cluster Type/Cluster Availability/Cluster Networking etc. get
  //  re-rendered even when Partitions change
  // We need to do something like the below but it is creating infinite loops somewhere
  // Note: This is done here because if we just use "values", then these fields will rerender even when other
  // unrelated fields like Partitions also change
  // const valuesToUse = getRelevantValues(clusterAvailabilityConfig, values);

  useEffect(() => {
    handleDependedClusterInputFields(
      columnConfig,
      dataUniverse,
      values,
      setFieldValue,
      setFieldTouched
    );
  }, [
    values,
    dataUniverse,
    setFieldValue,
    setFieldTouched,
    columnConfig.dependentFields,
    columnConfig,
  ]);
};

export const disableFollowerFetchOption = (setFieldValue) => {
  setFieldValue(CLUSTER_FOLLOWER_FETCH_BACKEND_NAME, false);
};

export const shouldShowFFOption = (values, estimate, isUserAdmin) => {
  return (
    values[CLUSTER_PROVIDER_BACKEND_NAME] === AWS &&
    values[CLUSTER_TYPE_BACKEND_NAME] === DEDICATED_CLUSTER_TYPE &&
    values[CLUSTER_NETWORKING_TYPE_BACKEND_NAME] === PEERED_VPC &&
    !getIfCustomerViewIsEnabled(estimate?.inputs) &&
    isUserAdmin
  );
};

export const fetchFormValues = (values, formName, formNameToFieldsMap) => {
  const fieldsInForm = formNameToFieldsMap.get(formName);
  return extractRelevantFieldValuesFromForm(fieldsInForm, values);
};

export const resetValues = (values, initialValues, resetForm, formName, formNameToFieldsMap) => {
  const fieldsInForm = formNameToFieldsMap.get(formName);
  resetFormValuesToInitialValues(values, fieldsInForm, initialValues, resetForm);
};

export const isFormEdited = (values, initialValues, formName, formNameToFieldsMap) => {
  const fieldsInForm = formNameToFieldsMap.get(formName);
  return isFormEditedBasedOnCurrentValues(values, initialValues, fieldsInForm);
};

export const isAnyOfTheGivenFormEdited = (
  values,
  initialValues,
  formNames,
  formNameToFieldsMap
) => {
  let toRet = false;
  for (const formName of formNames) {
    if (isFormEdited(values, initialValues, formName, formNameToFieldsMap)) {
      toRet = true;
      break;
    }
  }
  return toRet;
};

export const isAnyOfTheClusterRelatedFormsEdited = (values, initialValues) => {
  return isAnyOfTheGivenFormEdited(
    values,
    initialValues,
    [
      CLUSTER_DETAILS_FORM,
      CLUSTER_KAFKA_FORM,
      CLUSTER_KSQLDB_FORM,
      CLUSTER_CONNECTORS_FORM,
      CLUSTER_CLUSTER_LINKING_FORM,
    ],
    CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP
  );
};

export const isAnyOfTheFlinkPoolRelatedFormsEdited = (values, initialValues) => {
  return isAnyOfTheGivenFormEdited(
    values,
    initialValues,
    [FLINK_POOL_DETAILS_FORM, FLINK_POOL_MONTHLY_INPUTS_FORM],
    FLINK_POOL_DETAILS_FORM_NAMES_TO_FIELDS_MAP
  );
};

export const isEnterpriseCluster = (values) => {
  return values[CLUSTER_TYPE_BACKEND_NAME] === ENTERPRISE_CLUSTER_TYPE;
};
