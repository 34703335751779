// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG_MAP } from '@streaming-projects/resource-definitions/cluster-resource-definitions/cluster-resource-inputs-config';
import {
  AWS,
  AZURE,
  CLUSTER_PROVIDER_BACKEND_NAME,
  CLUSTER_REGION_BACKEND_NAME,
  GCP,
} from '@src/constants';
import { useClusterInputsDependencies } from '@components/cluster/cluster-details-inputs/utils';
import { getDropdownOptions } from '@components/stream-governance/utils';
import { SelectField } from '@src/formik-utils/FormFields';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';

export const SPClusterProviderContainer = ({
  disabled,
  fluid = null,
  disableOnFormErrors = null,
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const clusterProviderConfig = CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG_MAP.get(
    CLUSTER_PROVIDER_BACKEND_NAME
  );
  const dataUniverse =
    useContext(SPMetaDataContext).metaData.clusterResourceInputConfigs.cluster_params;

  useClusterInputsDependencies(
    clusterProviderConfig,
    dataUniverse,
    values,
    setFieldValue,
    setFieldTouched
  );

  const clusterProviderOptions = getDropdownOptions(
    dataUniverse,
    values,
    clusterProviderConfig,
    false,
    true,
    false,
    false,
    [AWS, AZURE, GCP]
  );

  return (
    <SelectField
      disableOnFormErrors={disableOnFormErrors}
      disabled={disabled}
      fieldDisplayName={clusterProviderConfig.displayName}
      fieldName={clusterProviderConfig.backendFieldName}
      fluid={fluid}
      onChange={async () => {
        // Clear out the region and force the user to select again
        await setFieldTouched(CLUSTER_REGION_BACKEND_NAME, true);
        await setFieldValue(CLUSTER_REGION_BACKEND_NAME, null);
      }}
      options={clusterProviderOptions}
      placeholder={null}
    />
  );
};
