// @flow
import React from 'react';
import { Menu } from 'semantic-ui-react';
import { useOktaAuth } from '@okta/okta-react';
// TODO configure eslint to support absolute imports
// eslint-disable-next-line import/no-unresolved
import { If } from '@presentational/If';

import { StyledTopBarMenu } from '../../common-utils/styledComponents';
import { UserContextProvider } from '../../contexts/UserContextProvider';

import { AppNameMenuItem } from './AppNameMenuItem';
import { WelcomeMessageMenuItem } from './WelcomeMessageMenuItem';
import { Hamburger } from './Hamburger';
import { DarkOrLightModeContainer } from './DarkOrLightModeContainer';
import { ViewTogglerLink } from './ViewTogglerLink';

export const TopBar = () => {
  const { authState } = useOktaAuth();
  const isAuthenticated = authState?.isAuthenticated;

  if (isAuthenticated === undefined || isAuthenticated === false) {
    return null;
  }

  return (
    <UserContextProvider>
      <StyledTopBarMenu>
        <Menu.Menu position="left">
          <AppNameMenuItem />
        </Menu.Menu>
        <Menu.Menu position="right">
          <WelcomeMessageMenuItem />
          {/* eslint-disable-next-line no-undef */}
          <If test={ENABLE_3_0}>
            <ViewTogglerLink />
          </If>
          <DarkOrLightModeContainer />
          <Hamburger />
        </Menu.Menu>
      </StyledTopBarMenu>
    </UserContextProvider>
  );
};
