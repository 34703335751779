// @flow
import React from 'react';
import { useLocation } from 'react-router-dom';
import { If } from '@presentational/If';
import { getLinkForStreamingProjectsHomePage } from '@streaming-projects/links';

import { getLinkForHomePage } from '../links/utils';

import { EstimatesViewLink } from './EstimatesViewLink';
import { StreamingProjectsViewLink } from './StreamingProjectsViewLink';

export const ViewTogglerLink = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      <If test={currentPath === getLinkForStreamingProjectsHomePage()}>
        <EstimatesViewLink />
      </If>

      <If test={currentPath === getLinkForHomePage()}>
        <StreamingProjectsViewLink />
      </If>
    </>
  );
};
