// @flow
import React from 'react';
import { FLINK_POOL_RESOURCES_GRID_HEADERS } from '@streaming-projects/orgs/enums';
import { SPFlinkPoolResourceDeleteWarningModal } from '@streaming-projects/resource-definitions/flink-resource-definitions/SPFlinkPoolResourceDeleteWarningModal';

import { DeleteRenderer } from '../../../views/grid-utils/renderers';

export const SPFlinkPoolDeleteRenderer = ({ data }) => {
  return (
    <DeleteRenderer>
      {(isDeleteModalOpen, setDeleteModalOpen) => {
        return (
          <SPFlinkPoolResourceDeleteWarningModal
            flinkPoolId={data[FLINK_POOL_RESOURCES_GRID_HEADERS.ID]}
            flinkPoolName={data[FLINK_POOL_RESOURCES_GRID_HEADERS.NAME]}
            isOpen={isDeleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            version={data.Version}
          />
        );
      }}
    </DeleteRenderer>
  );
};
