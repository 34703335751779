// @flow

import React, { useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  BASIC_CLUSTER_TYPE,
  CLUSTER_SLA_BACKEND_NAME,
  CLUSTER_TYPE_BACKEND_NAME,
} from '@src/constants';
import { Tooltip } from 'recharts';
import { CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG_MAP } from '@streaming-projects/resource-definitions/cluster-resource-definitions/cluster-resource-inputs-config';
import { getDropdownOptions } from '@components/stream-governance/utils';
import { SelectField } from '@src/formik-utils/FormFields';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';

const getTooltipBasedOnClusterType = (values) => {
  const clusterType = values?.[CLUSTER_TYPE_BACKEND_NAME];

  const tooltipText =
    clusterType === BASIC_CLUSTER_TYPE
      ? 'For Higher SLA, upgrade to a different cluster type.'
      : 'Lower SLA recommended for non-production workloads. \nHigher SLA recommended for production workloads.';

  return <Tooltip flowing={false} text={tooltipText} />;
};

export const SPClusterSlaContainer = ({ disabled, fluid = null, disableOnFormErrors = null }) => {
  const dataUniverse =
    useContext(SPMetaDataContext).metaData.clusterResourceInputConfigs.cluster_sla_configs;
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const clusterSlaConfig = CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG_MAP.get(CLUSTER_SLA_BACKEND_NAME);
  useEffect(() => {
    const currentSLAOption = values[clusterSlaConfig.backendFieldName];
    const clusterSlaOptions = getDropdownOptions(
      dataUniverse ?? [],
      values,
      clusterSlaConfig,
      false,
      true,
      false,
      true
    );
    if (!clusterSlaOptions.map((x) => x.name).includes(currentSLAOption)) {
      setFieldValue(CLUSTER_SLA_BACKEND_NAME, null).then(() => {});
      setFieldTouched(CLUSTER_SLA_BACKEND_NAME, true).then(() => {});
    }
    if (clusterSlaOptions.length === 1) {
      setFieldValue(CLUSTER_SLA_BACKEND_NAME, clusterSlaOptions[0].name).then(() => {});
    }
  }, [clusterSlaConfig, dataUniverse, setFieldTouched, setFieldValue, values]);

  const clusterSlaOptions = getDropdownOptions(
    dataUniverse,
    values,
    clusterSlaConfig,
    false,
    true,
    false,
    true
  );
  const tooltip = getTooltipBasedOnClusterType(values);

  return (
    <SelectField
      addLabel={true}
      disableOnFormErrors={disableOnFormErrors}
      disabled={disabled}
      fieldDisplayName={clusterSlaConfig.displayName}
      fieldName={clusterSlaConfig.backendFieldName}
      fluid={fluid}
      options={clusterSlaOptions}
      placeholder={null}
      tooltip={tooltip}
    />
  );
};
