// @flow
import { Formik } from 'formik';
import React from 'react';
import { object } from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import {
  useAddFlinkPoolResourceMutation,
  useUpdateFlinkPoolResourceMutation,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import {
  FLINK_POOL_RESOURCES_GRID_FIELDNAMES,
  FLINK_POOL_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { getValidationSchemaFromColsConfig } from '@src/configuration/utils';
import { FLINK_POOL_RESOURCE_INPUTS_DETAILS_CONFIG } from '@streaming-projects/resource-definitions/flink-resource-definitions/flink-resource-inputs-config';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';
import { Spacer } from '@presentational/spacing/Spacer';
import { toastError } from '@presentational/notifications/utils';
import { getLinkForOrganizationsPage } from '@streaming-projects/links';
import { FlinkPoolResourceNameContainer } from '@streaming-projects/resource-definitions/flink-resource-definitions/FlinkPoolResourceNameContainer';
import { FlinkPoolResourceProviderContainer } from '@streaming-projects/resource-definitions/flink-resource-definitions/FlinkPoolResourceProviderContainer';
import { FlinkPoolResourceRegionContainer } from '@streaming-projects/resource-definitions/flink-resource-definitions/FlinkPoolResourceRegionContainer';
import { ResourceEditRepriceWarningContainer } from '@streaming-projects/resource-definitions/auxilary-components/ResourceEditRepriceWarningContainer';

const getAddAndUpdateParams = (orgId, initialValues, addFlinkPoolFormik) => {
  // Note, flinkPoolResourceId and Version are only applicable in Editing Mode and hence the usage of "?."
  return {
    orgId,
    flinkPoolResourceId: initialValues?.[FLINK_POOL_RESOURCES_GRID_HEADERS.ID],
    payload: {
      org_id: orgId,
      name: addFlinkPoolFormik.values[FLINK_POOL_RESOURCES_GRID_FIELDNAMES.NAME],
      inputs: {
        ...addFlinkPoolFormik.values,
      },
      version: initialValues?.[FLINK_POOL_RESOURCES_GRID_HEADERS.VERSION],
    },
  };
};

export const SPFlinkConfigurationAddEditModal = ({
  isOpen,
  setOpen,
  isEditingMode = false,
  initialValues = null,
}) => {
  const { orgId } = useParams();
  const [addFlinkPoolResource] = useAddFlinkPoolResourceMutation();
  const [updateFlinkPoolResource] = useUpdateFlinkPoolResourceMutation();
  const { push } = useHistory();

  const initialValuesToUse = isEditingMode
    ? {
        [FLINK_POOL_RESOURCES_GRID_FIELDNAMES.NAME]:
          initialValues[FLINK_POOL_RESOURCES_GRID_HEADERS.NAME],
        [FLINK_POOL_RESOURCES_GRID_FIELDNAMES.PROVIDER]:
          initialValues[FLINK_POOL_RESOURCES_GRID_HEADERS.PROVIDER],
        [FLINK_POOL_RESOURCES_GRID_FIELDNAMES.REGION]:
          initialValues[FLINK_POOL_RESOURCES_GRID_HEADERS.REGION],
      }
    : {
        [FLINK_POOL_RESOURCES_GRID_FIELDNAMES.NAME]: '',
        [FLINK_POOL_RESOURCES_GRID_FIELDNAMES.PROVIDER]: '',
        [FLINK_POOL_RESOURCES_GRID_FIELDNAMES.REGION]: '',
      };
  const validationSchema = object({
    ...getValidationSchemaFromColsConfig(FLINK_POOL_RESOURCE_INPUTS_DETAILS_CONFIG),
  });

  return (
    <StyledContainer>
      <Formik
        autoComplete="off"
        enableReinitialize={true}
        initialValues={initialValuesToUse}
        onSubmit={() => {}}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {(addFlinkPoolFormik) => {
          return (
            <ConfirmModal
              body={
                <>
                  <Grid columns={1}>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <FlinkPoolResourceNameContainer
                          disableOnFormErrors={false}
                          disabled={false}
                          fluid={true}
                        />
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <FlinkPoolResourceProviderContainer
                          disableOnFormErrors={false}
                          disabled={false}
                          fluid={true}
                        />
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <FlinkPoolResourceRegionContainer
                          disableOnFormErrors={false}
                          disabled={false}
                          fluid={true}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Spacer y={20} />
                  {isEditingMode && (
                    <ResourceEditRepriceWarningContainer resourceType="Flink Pool" />
                  )}
                </>
              }
              cancelButtonNegative={true}
              disabled={!addFlinkPoolFormik.isValid || !addFlinkPoolFormik.dirty}
              header={
                isEditingMode
                  ? 'Edit Flink Pool Resource Configuration'
                  : 'Add New Flink Pool Resource Configuration'
              }
              isOpen={isOpen}
              okButtonNegative={false}
              okButtonText="Continue"
              onClickHandlerForCancel={() => {
                addFlinkPoolFormik.resetForm();
                return setOpen(false);
              }}
              onClickHandlerForOK={async () => {
                let errorInCreateOrUpdate;

                if (!isEditingMode) {
                  const { error } = await addFlinkPoolResource(
                    getAddAndUpdateParams(orgId, initialValues, addFlinkPoolFormik)
                  );
                  errorInCreateOrUpdate = error;
                } else {
                  const { error } = await updateFlinkPoolResource(
                    getAddAndUpdateParams(orgId, initialValues, addFlinkPoolFormik)
                  );
                  errorInCreateOrUpdate = error;
                }

                if (errorInCreateOrUpdate) {
                  toastError(errorInCreateOrUpdate);
                } else {
                  push(getLinkForOrganizationsPage(orgId));
                }

                setOpen(false);
              }}
            />
          );
        }}
      </Formik>
    </StyledContainer>
  );
};
