// @flow
import { useHistory, useParams } from 'react-router-dom';
import React from 'react';
import { getLinkForOrganizationsPage } from '@streaming-projects/links';
import { getDeleteWithWarningHeaderAndBody } from '@src/common-utils/utils';
import { useDeleteStreamingProjectMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { toastError, toastSuccess } from '@presentational/notifications/utils';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';

export const SPDeleteWarningModal = ({ isOpen, onClose, spName, spId, version = null }) => {
  const [deleteSP] = useDeleteStreamingProjectMutation();
  const { orgId } = useParams();
  const { push } = useHistory();
  const { header, headerBody } = getDeleteWithWarningHeaderAndBody('Streaming Project', spName);

  const onClickHandlerForOK = async () => {
    const { error } = await deleteSP({
      spId: spId,
      orgId: orgId,
      payload: {
        version: version,
      },
    });
    if (error) {
      toastError(error);
    } else {
      toastSuccess('Streaming Project delete was successful!');
      push(getLinkForOrganizationsPage(orgId));
    }
  };

  return (
    <ConfirmModal
      body={headerBody}
      header={header}
      isOpen={isOpen}
      onClickHandlerForCancel={onClose}
      onClickHandlerForOK={onClickHandlerForOK}
    />
  );
};
