// @flow
import { useHistory, useParams } from 'react-router-dom';
import React from 'react';
import { getLinkForOrganizationsPage } from '@streaming-projects/links';
import { getDeleteWithWarningHeaderAndBody } from '@src/common-utils/utils';
import { useDeleteClusterResourceMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { toastError, toastSuccess } from '@presentational/notifications/utils';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';

export const SPClusterResourceDeleteWarningModal = ({
  isOpen,
  onClose,
  clusterName,
  clusterId,
  version = null,
}) => {
  const [deleteClusterResource] = useDeleteClusterResourceMutation();
  const { orgId } = useParams();
  const { push } = useHistory();
  const { header, headerBody } = getDeleteWithWarningHeaderAndBody('Cluster Resource', clusterName);

  const onClickHandlerForOK = async () => {
    const { error } = await deleteClusterResource({
      clusterResourceId: clusterId,
      orgId: orgId,
      payload: {
        version: version,
      },
    });
    if (error) {
      toastError(error);
    } else {
      toastSuccess('Cluster Resource delete was successful!');
      push(getLinkForOrganizationsPage(orgId));
    }
  };

  return (
    <ConfirmModal
      body={headerBody}
      header={header}
      isOpen={isOpen}
      onClickHandlerForCancel={onClose}
      onClickHandlerForOK={onClickHandlerForOK}
    />
  );
};
